import PropTypes from 'prop-types'
import ReachTooltip from '@reach/tooltip'
import '@reach/tooltip/styles.css'
import * as styles from './Tooltip.module.scss'

const Tooltip = ({ tooltipText, children, ...props }) => {
  // Center the tooltip, but collisions will win
  const centered = (triggerRect, tooltipRect) => {
    const triggerCenter = triggerRect.left + triggerRect.width / 2
    const left = triggerCenter - tooltipRect.width / 2
    const maxLeft = window.innerWidth - tooltipRect.width - 2
    return {
      left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
      top: triggerRect.bottom + 8 + window.scrollY,
    }
  }

  return (
    <span className={styles.Tooltip}>
      <ReachTooltip label={tooltipText} data-qa="tooltip-container" position={centered} {...props}>
        <button type="button">{children}</button>
      </ReachTooltip>
    </span>
  )
}

Tooltip.propTypes = {
  tooltipText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export { Tooltip }
export default Tooltip
