import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import qs from 'qs'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'
import { UnstyledLink } from '../../typography/UnstyledLink'
import { ScreenReaderText } from '../../screen-reader-text'
import { Button } from '../../button'
import { List } from '../../list'
import { findTopLevelNavItem, checkShouldShowSubNav } from '../utils'
import { Container, Row } from '../../grid'
import { HamburgerMenu } from '../HamburgerMenu'
import * as styles from '../Header.module.scss'

const SubNav = ({ showSubnav, showSubnavOnScroll, isBusiness, menu, pageContext, location }) => {
  const queryParams = qs.parse('', { ignoreQueryPrefix: true })
  const displaySearchResults = !!queryParams.q

  const [menuOpen, setMenuOpen] = useState(displaySearchResults ? 'search' : false)
  const openSearchMenu = () => setMenuOpen('search')
  const openHamburgerMenu = () => setMenuOpen('hamburger')
  const closeMenu = () => setMenuOpen(false)

  const [t, i18n] = useTranslation()

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
  })
  const observerHasInitialized = Boolean(entry)

  // Heads up! We use two different nav menus for sub nav and main menu
  const currentPage = findTopLevelNavItem(pageContext.pathname, menu.subnav)

  const shouldShowSubnav = checkShouldShowSubNav(
    showSubnav,
    showSubnavOnScroll,
    currentPage,
    observerHasInitialized,
    inView
  )

  return (
    <>
      <div ref={ref} />

      <HamburgerMenu
        location={location}
        pageContext={pageContext}
        menu={menu}
        open={menuOpen}
        close={closeMenu}
      />

      <nav
        role="navigation"
        aria-label={t('Header.secondaryMenuButtonAriaLabel', { defaultValue: 'Secondary Menu' })}
        className={classNames(
          styles.SecondaryNavContainer,
          shouldShowSubnav && styles.SubNavVisible
        )}
        data-qa="subnav"
      >
        <Container>
          <Row>
            <div className={styles.SecondaryNav}>
              <List className={styles.SecondaryNavList} unstyled>
                <List.Item>
                  {observerHasInitialized && !inView && (
                    <UnstyledLink
                      to="/"
                      className={styles.HomeLink}
                      alt={t('Header.homeLinkAriaLabel', {
                        defaultValue: 'Elevations home',
                      })}
                      data-qa="home-link"
                    >
                      <svg
                        role="img"
                        aria-hidden="true"
                        focusable="false"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 138 85"
                        className={styles.LogoSmall}
                      >
                        <g fill="none" fillRule="nonzero">
                          <path
                            fill="#63B5E5"
                            d="m113.946 10.642c-20.087 15.556-43.297 53.099-47.082 63.011-.351.92.327 1.743 1.196 2.025.573.185 2.013.46 2.732.5 1.313.074 2.001-.863 2.274-1.449 8.934-19.415 26.124-50.1 41.735-63.541.665-.574.172-1.397-.855-.546"
                          />
                          <path
                            fill="#84BD41"
                            d="m50.94 28.434c-.173-.029-.327-.216-.321-.382l.185-8.084c.005-.188.169-.339.374-.31 0 0 25.906 4.171 32.87 5.034.053.005.301-.07.34-.104 10.212-8.879 19.441-14.926 26.116-18.756 6.661-3.821 10.778-5.431 10.796-5.439.577-.216 1.351-.361 1.614-.037.32.39.137.895-.497 1.261-29.058 16.89-49.867 49.289-61.019 71.835-.218.436-.553.631-.964.695-.416.063-.906-.003-1.442-.093-17.21-2.808-58.399-10.493-58.399-10.493-.157-.025-.318-.206-.321-.364l-.27206876-11.742c-.00393124-.211.16706876-.363.38506876-.327l54.452 9.061c.121.018.381-.102.443-.205 1.237-2.036 2.666-4.234 3.787-5.95.58-.893 1.08-1.657 1.421-2.197l.199-.314c.188-.305.059-.595-.29-.66l-35.337-6.582c-.159-.027-.317-.208-.32-.368l-.236-10.189c-.003-.208.166-.361.385-.327l43.438 7.58c.088.015.349-.081.407-.15 1.625-1.922 3.371-3.876 4.71-5.365.692-.767 1.274-1.415 1.673-1.868.391-.448.273-.782-.13-.852z"
                          />
                          <path
                            fill="#009cde"
                            d="m128.909 0c1.624 0 2.909 1.3 2.909 2.932s-1.285 2.931-2.909 2.931c-1.623 0-2.909-1.299-2.909-2.931s1.286-2.932 2.909-2.932zm0 .634c-1.254 0-2.275.984-2.275 2.298 0 1.307 1.021 2.297 2.275 2.297 1.256 0 2.276-.99 2.276-2.297 0-1.314-1.02-2.298-2.276-2.298z"
                          />
                          <path
                            fill="#009cde"
                            d="m129.169 1.396c.701 0 1.18.126 1.18.931 0 .561-.287.863-.826.899l.738 1.213h-.694l-.709-1.213h-.533v1.213h-.634v-3.043zm-.058182.54724869-.785818-.00024869v.738h.859c.339 0 .545-.074.545-.407 0-.331-.406-.331-.701-.331z"
                          />
                        </g>
                      </svg>
                    </UnstyledLink>
                  )}
                </List.Item>
                <List.Item className={styles.SecondaryNavItem}>
                  <UnstyledLink
                    to={currentPage.urlPath}
                    className={styles.BannerNavLink}
                    partiallyActive={currentPage.partiallyActive}
                    activeClassName={styles.active}
                    alt={currentPage.ariaLabel}
                  >
                    {currentPage.title}
                  </UnstyledLink>
                </List.Item>

                {currentPage.children.map(page => (
                  <List.Item key={page.title} className={styles.SecondaryNavItem}>
                    <UnstyledLink
                      to={page.urlPath}
                      className={styles.SecondaryNavLink}
                      partiallyActive={page.partiallyActive}
                      activeClassName={styles.active}
                      alt={page.ariaLabel}
                      aria-label={page.ariaLabel}
                    >
                      {page.title}
                    </UnstyledLink>
                  </List.Item>
                ))}
              </List>

              {observerHasInitialized && !inView && (
                <List className={styles.SecondaryBannerNavList} unstyled>
                  <List.Item className={styles.BannerNavItem}>
                    <Button
                      url={`/login${qs.stringify(isBusiness ? { action: 'business' } : {}, {
                        addQueryPrefix: true,
                      })}`}
                      alt={
                        isBusiness
                          ? t('Header.businessLoginButtonAriaLabel', {
                              defaultValue: 'Business Log In',
                            })
                          : undefined
                      }
                    >
                      {t('Header.loginButtonLabel', { defaultValue: 'Log In' })}
                    </Button>
                  </List.Item>

                  <List.Item className={styles.BannerNavItem}>
                    <button type="button" className={styles.SearchToggle} onClick={openSearchMenu}>
                      {i18n.language === 'en-US' ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={42}
                          height={40}
                          viewBox="0 0 42 40"
                        >
                          <g fill="#707070">
                            <path d="M26.294 26.579h-.903l-.32-.31c1.12-1.303 1.795-2.995 1.795-4.836 0-4.105-3.328-7.433-7.433-7.433S12 17.328 12 21.433s3.328 7.433 7.433 7.433c1.84 0 3.533-.675 4.837-1.796l.309.32v.904L30.296 34 32 32.296l-5.706-5.717zm-6.861 0c-2.848 0-5.146-2.299-5.146-5.146 0-2.848 2.298-5.146 5.146-5.146 2.847 0 5.146 2.298 5.146 5.146 0 2.847-2.299 5.146-5.146 5.146zM2.89 8C4.536 8 5.56 6.87 5.56 5.626c0-1.119-.72-1.763-2.006-2.057l-1.156-.26c-.654-.147-.992-.427-.992-1.003 0-.746.621-1.334 1.494-1.334 1.079 0 1.548.791 1.635 1.368l.96-.35C5.32 1.118 4.623 0 2.879 0 1.45 0 .327 1.13.327 2.385c0 1.198.785 1.783 1.887 2.032l1.1.249c.775.17 1.157.474 1.157 1.05 0 .702-.524 1.278-1.592 1.278-1.167 0-1.778-.825-1.854-1.718L0 5.616C.142 6.755 1.025 8 2.89 8zm8.389 0v-.828H7.648V4.375h3.293v-.823H7.648V.828h3.63V0H6.58v8h4.7zm2.163 0l.818-2.234h3.424L18.524 8h1.155l-3.075-8h-1.232l-3.075 8h1.145zm10.37-8c1.461 0 2.366 1.06 2.366 2.38 0 1.152-.709 2.043-1.876 2.269L26.145 8h-1.243l-1.766-3.272H21.98V8H20.9V0zM15.96 1l1.341 4h-2.66l1.319-4zm7.655 0H21.98v3h1.636c.872 0 1.46-.58 1.46-1.481 0-.926-.588-1.519-1.46-1.519zm7.48 7c1.777 0 2.998-1.07 3.413-2.57l-.949-.36c-.316 1.093-1.145 1.916-2.464 1.916-1.407 0-2.748-.87-2.748-2.955s1.341-3.017 2.748-3.017c1.265 0 2.115.677 2.41 1.883l.992-.361C34.105.992 32.862 0 31.096 0c-1.92 0-3.838 1.36-3.838 4.03C27.258 6.703 29.1 8 31.096 8zm5.888 0V4.446h3.947V8H42V0h-1.069v3.419h-3.947V0h-1.069v8h1.07z" />
                          </g>
                        </svg>
                      ) : (
                        <svg
                          width={42}
                          height={40}
                          viewBox="0 0 42 40"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="nonzero">
                            <path
                              d="M26.3 26.58h-.9l-.33-.31a7.43 7.43 0 10-.8.8l.3.32v.9L30.3 34l1.7-1.7-5.7-5.72zm-6.87 0a5.14 5.14 0 11-.01-10.28 5.14 5.14 0 01.01 10.28z"
                              fill="#707070"
                            />
                            <path
                              d="M2.23 7.9c1.17 0 1.97-.73 1.97-1.74 0-.7-.42-1.35-1.15-1.53.56-.21.96-.7.96-1.38 0-1-.72-1.73-1.93-1.73H0V7.9h2.23zm-.25-3.59H.88V2.3h1.06c.73 0 1.17.4 1.17 1 0 .59-.42 1.01-1.13 1.01zm.13 2.82H.88V5.07h1.26c.78 0 1.16.43 1.16 1.04 0 .62-.48 1.02-1.2 1.02zm5.07-6.06L8.41 0H7.24l-.9 1.07h.84zm-.2 6.97c1.3 0 2.3-.81 2.3-2.32v-4.2H8.4V5.7c0 .97-.52 1.52-1.42 1.52-.89 0-1.42-.55-1.42-1.52V1.52H4.7v4.2c0 1.5 1 2.32 2.3 2.32zm5 0c1.36 0 2.21-.9 2.21-1.9 0-.88-.6-1.55-1.66-1.78l-.95-.21c-.54-.12-.82-.47-.82-.93 0-.6.51-1.06 1.23-1.06.9 0 1.28.63 1.35 1.09l.8-.28c-.15-.7-.72-1.58-2.16-1.58-1.18 0-2.1.9-2.1 1.9 0 .95.64 1.54 1.55 1.74l.9.2c.65.13.96.53.96.99 0 .56-.43 1.02-1.31 1.02-.97 0-1.47-.66-1.53-1.37l-.85.27c.12.9.85 1.9 2.39 1.9zm7.93.4l.6-.54-.74-.83c.54-.56.9-1.36.9-2.36 0-2.14-1.6-3.32-3.2-3.32s-3.2 1.18-3.2 3.32c0 2.13 1.6 3.33 3.2 3.33.58 0 1.17-.16 1.67-.46l.77.85zm-2.44-1.21c-1.2 0-2.3-.86-2.3-2.52 0-1.67 1.1-2.51 2.3-2.51 1.18 0 2.3.84 2.3 2.5 0 .75-.23 1.32-.57 1.73L18.1 5.18l-.62.53 1.11 1.24c-.34.19-.72.28-1.11.28zm5.93.8c1.3 0 2.29-.8 2.29-2.3v-4.2h-.88V5.7c0 .97-.51 1.52-1.41 1.52-.9 0-1.43-.55-1.43-1.52V1.52h-.87v4.2c0 1.5 1 2.32 2.3 2.32zm7.02-.13v-.82h-3V5.13h2.72V4.3h-2.71V2.34h3v-.82h-3.89V7.9h3.88zm2.76 0c1.62 0 3.06-1.09 3.06-3.18 0-2.1-1.42-3.2-3.04-3.2h-2.23V7.9h2.21zm-.02-.78h-1.3V2.3h1.32c1.17 0 2.15.81 2.15 2.42 0 1.6-1 2.4-2.17 2.4zm3.6.78l.67-1.78h2.83l.7 1.78h.94l-2.53-6.38h-1.02L35.81 7.9h.95zm3.18-2.6h-2.2l1.1-2.86 1.1 2.86z"
                              fill="#7B7B7B"
                            />
                          </g>
                        </svg>
                      )}
                      <ScreenReaderText>
                        {t('Header.searchMenuButtonAriaLabel', {
                          defaultValue: 'Open Search Menu',
                        })}
                      </ScreenReaderText>
                    </button>
                  </List.Item>

                  <List.Item className={styles.BannerNavItem}>
                    <button
                      type="button"
                      className={styles.MenuToggle}
                      onClick={openHamburgerMenu}
                      data-qa="menu-toggle"
                    >
                      {i18n.language === 'en-US' ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={32}
                          height={34}
                          role="img"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <path
                            d="M31.571 18c.237 0 .429-.299.429-.667v-2.666c0-.368-.192-.667-.429-.667H.43c-.237 0-.429.299-.429.667v2.666c0 .368.192.667.429.667h31.14zm-7.892 8c.177 0 .321-.299.321-.667v-2.666c0-.368-.144-.667-.321-.667H.32c-.177 0-.321.299-.321.667v2.666c0 .368.144.667.321.667h23.36zm-7.893 8c.118 0 .214-.299.214-.667v-2.666c0-.368-.096-.667-.214-.667H.214c-.118 0-.214.299-.214.667v2.666c0 .368.096.667.214.667h15.572zM1.075 7.892V4.818l-.107-3.3 2.67 6.374h.824l2.676-6.39-.101 3.316v3.074h1.075V0H6.718L4.053 6.439 1.388 0H0v7.892h1.075zm14.634 0V7.04h-4.104V4.244h3.533v-.851h-3.533V.856h4.048V0H10.53v7.892h5.179zm2.94 0V1.81l4.103 6.082h1.08V0h-1.07v6.108L18.649 0h-1.08v7.892h1.08zM29.038 8l.285-.01c.821-.059 1.471-.324 1.951-.798.48-.473.721-1.082.725-1.826V0h-1.08v5.35c0 .57-.162 1.013-.485 1.328-.322.314-.788.471-1.396.471-.601 0-1.064-.158-1.389-.474-.324-.316-.487-.76-.487-1.33V0H26.1v5.377c.007.816.276 1.458.806 1.924.53.466 1.24.699 2.133.699z"
                            fill="#707070"
                          />
                        </svg>
                      ) : (
                        <svg
                          width={34}
                          height={40}
                          viewBox="0 0 34 40"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="M16.79 30c.11 0 .21.3.21.67v2.66c0 .37-.1.67-.21.67H1.2c-.11 0-.21-.3-.21-.67v-2.66c0-.37.1-.67.21-.67zm7.89-8c.18 0 .32.3.32.67v2.66c0 .37-.14.67-.32.67H1.32c-.18 0-.32-.3-.32-.67v-2.66c0-.37.14-.67.32-.67zm7.9-8c.23 0 .42.3.42.67v2.66c0 .37-.2.67-.43.67H1.43c-.24 0-.43-.3-.43-.67v-2.66c0-.37.2-.67.43-.67zM28.15 2v5.53c.04.48.2.86.49 1.14.32.32.79.48 1.39.48.6 0 1.07-.16 1.4-.47.32-.32.48-.76.48-1.33V2H33v5.59a2.42 2.42 0 01-.72 1.6c-.48.48-1.13.74-1.96.8l-.28.01c-.9 0-1.6-.23-2.13-.7-.53-.47-.8-1.1-.81-1.92V2h1.06zM2.4 2l2.66 6.44L7.72 2h1.4v7.9H8.03V6.81l.1-3.32-2.68 6.4h-.82L1.97 3.51l.1 3.3v3.07H1V2h1.39zm14.26 0v.86h-4.04v2.53h3.53v.85H12.6v2.8h4.1v.85h-5.18V2h5.12zm3 0l4.11 6.1V2h1.07v7.9h-1.08l-4.1-6.09v6.08h-1.08V2h1.08z"
                              fill="#707070"
                            />
                            <path fill="#7B7B7B" d="M29.85 2.07L31.4.73h-1.17L29 2.07z" />
                          </g>
                        </svg>
                      )}

                      <ScreenReaderText>
                        {t('Header.primaryMenuButtonAriaLabel', {
                          defaultValue: 'Open Primary Menu',
                        })}
                      </ScreenReaderText>
                    </button>
                  </List.Item>
                </List>
              )}
            </div>
          </Row>
        </Container>
      </nav>
    </>
  )
}

SubNav.propTypes = {
  menu: PropTypes.object,
  showSubnav: PropTypes.bool,
  showSubnavOnScroll: PropTypes.bool,
  observerHasInitialized: PropTypes.bool,
  inView: PropTypes.bool,
  openSearchMenu: PropTypes.bool,
  openHamburgerMenu: PropTypes.bool,
  isBusiness: PropTypes.bool,
  pageContext: PropTypes.object,
  location: PropTypes.object,
}

export { SubNav }
export default SubNav
