// extracted by mini-css-extract-plugin
export var Header = "Header-module--Header--1b-vw";
export var headerSticky = "Header-module--headerSticky--VKvEN";
export var TransparentBg = "Header-module--TransparentBg--3Cn10";
export var HomeLink = "Header-module--HomeLink--1gU_g";
export var LogoMark = "Header-module--LogoMark--yMiiR";
export var LogoName = "Header-module--LogoName--JN_Qj";
export var LogoSmall = "Header-module--LogoSmall--2UW41";
export var fadeIn = "Header-module--fadeIn--2MQCN";
export var BannerNavList = "Header-module--BannerNavList--2GZuR";
export var SecondaryBannerNavList = "Header-module--SecondaryBannerNavList--1D3Bq";
export var BannerNavItem = "Header-module--BannerNavItem--oYiOR";
export var WhyElevationsContainer = "Header-module--WhyElevationsContainer--3hxOJ";
export var BannerNavLink = "Header-module--BannerNavLink--qHzK3";
export var MainNavLink = "Header-module--MainNavLink--3neEo";
export var MenuToggle = "Header-module--MenuToggle--21ooZ";
export var SearchToggleContainer = "Header-module--SearchToggleContainer--3TEpi";
export var SearchToggle = "Header-module--SearchToggle--2xDI1";
export var LinkIcon = "Header-module--LinkIcon--37kCT";
export var SecondaryNavContainer = "Header-module--SecondaryNavContainer--1m_F2";
export var navigationButtons = "Header-module--navigationButtons--1mVMs";
export var active = "Header-module--active--3wiLI";
export var navigationButtonsActive = "Header-module--navigationButtonsActive--1yTdD";
export var navButtonContainer = "Header-module--navButtonContainer--1Wb-n";
export var SubNavVisible = "Header-module--SubNavVisible--8sCEt";
export var SecondaryNav = "Header-module--SecondaryNav--1EL96";
export var SecondaryNavList = "Header-module--SecondaryNavList--1HBH1";
export var SecondaryNavItem = "Header-module--SecondaryNavItem--3wHJT";
export var SecondaryNavLink = "Header-module--SecondaryNavLink--1fYTC";
export var LangSwitcher = "Header-module--LangSwitcher--1J_eh";
export var LangSwitcherLabelLong = "Header-module--LangSwitcherLabelLong--aF6ea";
export var LangSwitcherLabelShort = "Header-module--LangSwitcherLabelShort--2Dadd";
export var LogInLabel = "Header-module--LogInLabel--1uYyt";
export var LogInShortLabel = "Header-module--LogInShortLabel--Em1V6";