import PropTypes from 'prop-types'

const EnergyLoanRates = ({ theme }) => {
  return (
    <table className={theme}>
      <caption>Energy Loan Rates</caption>
      <thead>
        <tr>
          <th scope="col">Terms</th>
          <th scope="col">
            APR Starting At<sup>*</sup>
          </th>
          <th scope="col">
            APR Up To<sup>*</sup>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">36-Month</th>
          <td>2.750%</td>
          <td>5.375%</td>
        </tr>
        <tr>
          <th scope="row">60-Month</th>
          <td>3.500%</td>
          <td>6.125%</td>
        </tr>
        <tr>
          <th scope="row">84-Month</th>
          <td>4.500%</td>
          <td>7.125%</td>
        </tr>
        <tr>
          <th scope="row">120-Month</th>
          <td>5.000%</td>
          <td>7.625%</td>
        </tr>
        <tr>
          <th scope="row">180-Month</th>
          <td>5.500%</td>
          <td>8.125%</td>
        </tr>
      </tbody>
    </table>
  )
}

EnergyLoanRates.propTypes = {
  theme: PropTypes.string,
}

export default EnergyLoanRates
