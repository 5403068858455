import { useEffect } from 'react'

const useAutofocus = (ref, autoFocus = true) => {
  useEffect(() => {
    if (autoFocus) {
      ref.current.focus()
    }
  }, [ref])
}

export default useAutofocus
