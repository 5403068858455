import { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { Link } from '../typography/Link'
import { TableModule } from '../modules/Table'
import CloseImage from '../../images-global/close.svg'
import * as styles from './ModalTableLink.module.scss'

const ModalTableLink = ({ to, children }) => {
  const [t] = useTranslation()
  const [showDialog, setShowDialog] = useState(false)
  const close = () => setShowDialog(false)

  const onClick = e => {
    e.preventDefault()
    setShowDialog(true)
  }

  return (
    <Fragment>
      <Link to={to} onClick={onClick}>
        {children}
      </Link>

      <DialogOverlay className={styles.DialogOverlay} isOpen={showDialog} onDismiss={close}>
        <DialogContent className={styles.DialogContent}>
          <button
            type="button"
            className={styles.CloseButton}
            onClick={close}
            data-qa="modal-table-link-close-button"
          >
            <img
              src={CloseImage}
              alt={t('ModalTableLink.closeButtonLabel', { defaultValue: 'Close' })}
            />
          </button>
          <TableModule
            headline={t('ModalTableLink.holidayDisclosuresTableTitle', {
              defaultValue: 'Holiday Closures',
            })}
            table="holiday-hours"
            backgroundColor="white"
          />
        </DialogContent>
      </DialogOverlay>
    </Fragment>
  )
}

ModalTableLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export { ModalTableLink }
export default ModalTableLink
