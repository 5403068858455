import { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getBrowser } from '../../utilities/detectBrowser'
import { Heading } from '../typography/Heading'
import { Modal } from '../modal'
import * as styles from './UnsupportedBrowserModal.module.scss'

const UnsupportedBrowserModal = memo(function UnsupportedBrowserModal({ targetBrowsers }) {
  const [showDialog, setShowDialog] = useState(true)
  const [detectedBrowser, setDetectedBrowser] = useState()
  const close = () => setShowDialog(false)

  useEffect(() => {
    const browser = getBrowser()
    setDetectedBrowser(browser)
  }, [])

  if (targetBrowsers.includes(detectedBrowser)) {
    return (
      <Modal open={showDialog} onDismiss={close} className={styles.UnsupportedBrowserModal}>
        <div className={styles.UnsupportedBrowserModalInner}>
          <Heading element="h1" size={2} data-qa="unsupported-browser-modal-headline">
            It looks like you're using an unsupported browser.
          </Heading>
          <Heading element="p" size={4} data-qa="unsupported-browser-modal-body">
            To get the most out of our website, please upgrade to Apple Safari, Google Chrome,
            Microsoft Edge or Mozilla Firefox.
          </Heading>
          <Heading element="p" size={4} data-qa="unsupported-browser-modal-body">
            Thanks for stopping by!
          </Heading>
        </div>
      </Modal>
    )
  }
  return null
})

UnsupportedBrowserModal.propTypes = {
  targetBrowsers: PropTypes.arrayOf(
    PropTypes.oneOf(['IE', 'Opera', 'Chrome', 'Safari', 'Firefox', 'Edge'])
  ),
}

export { UnsupportedBrowserModal }
export default UnsupportedBrowserModal
