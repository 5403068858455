import PropTypes, { element } from 'prop-types'
import classNames from 'classnames'
import * as styles from './Eyebrow.module.scss'

const Eyebrow = ({ element: Element = 'p', children, className, ...props }) => {
  const classes = classNames(styles.Eyebrow, className)
  // code to properly format ®
  console.log('element', Element)
  if (typeof document !== 'undefined') {
    const reserved = document.getElementsByTagName('h4')
    let text
    let newText
    for (let i = 0; i < reserved.length; i += 1) {
      text = reserved[i].innerHTML
      if (!text.includes('</span>')) {
        newText = text.replace(
          /®/gi,
          "<span style='font-size: .4em; vertical-align: super'>®</span>"
        )
        document.getElementsByTagName('h4')[i].innerHTML = newText
      }
    }
    const reservedH = document.getElementsByTagName('h2')
    let textH
    let newTextH
    for (let i = 0; i < reservedH.length; i += 1) {
      textH = reservedH[i].innerHTML
      if (!textH.includes('</span>')) {
        newTextH = textH.replace(
          /®/gi,
          "<span style='font-size: .4em; vertical-align: super'>®</span>"
        )
        document.getElementsByTagName('h2')[i].innerHTML = newTextH
      }
    }
    // formatting for ™
    const reservedTM = document.getElementsByTagName('h3')
    let textTM
    let newTextTM
    for (let i = 0; i < reservedTM.length; i += 1) {
      textTM = reservedTM[i].innerHTML
      if (!textTM.includes('</span>')) {
        newTextTM = textTM.replace(
          /™/gi,
          "<span style='font-size: .4em; vertical-align: super'>™</span>"
        )
        document.getElementsByTagName('h3')[i].innerHTML = newTextTM
      }
    }
  }
  return (
    <Element className={classes} data-qa="eyebrow" {...props}>
      {children}
    </Element>
  )
}

Eyebrow.propTypes = {
  element: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']),
  children: PropTypes.node,
  className: PropTypes.string,
}

export { Eyebrow }
export default Eyebrow
