/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import * as styles from './NavLink.module.scss'

import { Link } from '../../../typography/Link'
import { Eyebrow } from '../../../typography/Eyebrow'

const NavLink = ({
  label,
  to,
  isTopLevel,
  dark,
  children,
  openSubmenuId,
  setSubnavOpen,
  subNavId,
  id,
  closeMenu,
  onClickOutside,
}) => {
  const [t] = useTranslation()
  const isOpen = openSubmenuId === subNavId

  const setOpenItem = e => {
    e.stopPropagation()

    if (children && children.length > 0 && !isOpen) {
      e.preventDefault()
      setSubnavOpen(subNavId)
    } else if (children && children.length > 0 && isOpen) {
      e.preventDefault()
      setSubnavOpen(null)
    } else {
      setSubnavOpen(null)
    }
  }

  const isExpanded = isTopLevel ? !!isOpen : false

  const getTabIndex = () => {
    if (isTopLevel) {
      if (openSubmenuId !== null) {
        return -1
      }
    }

    if (!isTopLevel) {
      if (isOpen) {
        return 0
      }
      return -1
    }
    return 0
  }

  const tabIndex = getTabIndex()

  const renderNavItem = () => {
    if (dark) {
      if (isTopLevel && children && children.length > 0) {
        return (
          <button
            type="button"
            className={isTopLevel ? styles.NavLinkDark : styles.SubLinkDark}
            aria-expanded={isExpanded}
            id={id}
            data-qa="hamburger-menu-nav-link"
            tabIndex={tabIndex}
          >
            {label}
            <FontAwesomeIcon aria-hidden icon={faChevronRight} className={styles.RightChevron} />
          </button>
        )
      }
      return (
        <Link
          className={isTopLevel ? styles.NavLinkDark : styles.SubLinkDark}
          role="menuitem"
          to={to}
          id={id}
          data-qa="hamburger-menu-sub-nav-link"
          tabIndex={tabIndex}
          onClick={closeMenu}
        >
          {label}
        </Link>
      )
    }
    if (isTopLevel && children && children.length > 0) {
      return (
        <button
          type="button"
          className={isTopLevel ? styles.NavLink : styles.SubLink}
          aria-expanded={isExpanded}
          id={id}
          data-qa="hamburger-menu-nav-link"
          tabIndex={tabIndex}
        >
          {label}

          <FontAwesomeIcon aria-hidden icon={faChevronRight} className={styles.RightChevron} />
        </button>
      )
    }
    return (
      <Link
        type="button"
        className={isTopLevel ? styles.NavLink : styles.SubLink}
        role="menuitem"
        to={to}
        id={id}
        onClick={closeMenu}
        data-qa="hamburger-menu-sub-nav-link"
        tabIndex={tabIndex}
      >
        {label}
      </Link>
    )
  }
  return (
    <li role="presentation" onClick={setOpenItem}>
      {renderNavItem()}

      {children && (
        <ul
          className={classNames(
            dark ? styles.SubmenuDark : styles.Submenu,
            isOpen && styles.SubmenuOpen
          )}
          role="presentation"
        >
          <li className={dark ? styles.SubMenuHeadingDark : styles.SubmenuHeading}>
            <FontAwesomeIcon
              aria-hidden
              icon={faChevronLeft}
              className={dark ? styles.LeftChevronDark : styles.LeftChevron}
            />
            <button
              type="button"
              className={dark ? styles.BackButtonDark : styles.BackButton}
              aria-expanded={isExpanded}
              onClick={() => setSubnavOpen(null)}
            >
              {label}
            </button>
          </li>
          <li>
            <Link
              className={dark ? styles.SubLinkDark : styles.SubLink}
              role="menuitem"
              to={to}
              id={id}
              onClick={closeMenu}
              data-qa="hamburger-menu-sub-nav-link"
            >
              {t('NavLink.overviewLinkLabel', { defaultValue: 'Overview' })}
            </Link>
          </li>
          {children}
        </ul>
      )}
    </li>
  )
}

NavLink.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
  isTopLevel: PropTypes.bool,
  children: PropTypes.node,
  openSubmenuId: PropTypes.string,
  setSubnavOpen: PropTypes.func,
  subNavId: PropTypes.string,
  id: PropTypes.string,
  closeMenu: PropTypes.func,
  dark: PropTypes.bool,
  onClickOutside: PropTypes.func,
}

export { NavLink }
export default NavLink
