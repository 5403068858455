import { Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Item } from './components/Item'
import * as styles from './List.module.scss'

const List = ({ type = 'bullet', unstyled, className, children, ...props }) => {
  let Element
  if (type === 'number') {
    Element = 'ol'
  } else {
    Element = 'ul'
  }

  const checklist = type === 'checklist'

  const classes = classNames(
    styles.List,
    unstyled && styles.unstyled,
    checklist && styles.unstyled,
    className
  )

  const listItems = Children.map(children, child =>
    cloneElement(child, { _customBullet: checklist ? 'check' : 'none' })
  )

  return (
    <Element className={classes} data-qa={`list-${unstyled ? 'unstyled' : type}`} {...props}>
      {listItems}
    </Element>
  )
}

List.propTypes = {
  type: PropTypes.oneOf(['checklist', 'bullet', 'number']),
  unstyled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
}

List.Item = Item

export { List }
export default List
