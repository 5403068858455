import { useTranslation } from 'react-i18next'
import { WithSearch } from '@elastic/react-search-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { ScreenReaderText } from '../../../screen-reader-text'
import * as styles from './ResetButton.module.scss'

const ResetButton = () => {
  const [t] = useTranslation()

  return (
    <WithSearch mapContextToProps={({ searchTerm, reset }) => ({ searchTerm, reset })}>
      {context =>
        context.searchTerm && (
          <button
            type="button"
            className={styles.ClearButton}
            onClick={() => {
              context.reset()
            }}
          >
            <ScreenReaderText>
              {t('ResetButton.clearButtonAriaLabel', { defaultValue: 'Clear input' })}
            </ScreenReaderText>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )
      }
    </WithSearch>
  )
}

export { ResetButton }
export default ResetButton
