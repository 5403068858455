import { useRef } from 'react'
import PropTypes from 'prop-types'

import { DialogOverlay, DialogContent } from '@reach/dialog'
import { Button } from '../button'
import { Stack } from '../stack'

import * as styles from './LocaleSwitcherModal.module.scss'

const SpanishAvailable = ({ isOpen, onDismiss, onConfirm, onCancel }) => {
  const confirmButtonRef = useRef(null)

  return (
    <DialogOverlay
      className={styles.Backdrop}
      isOpen={isOpen}
      onDismiss={onDismiss}
      initialFocusRef={confirmButtonRef}
      data-qa="modal-overlay"
    >
      <DialogContent
        className={styles.ContentArea}
        aria-label="Indicador de cambio de idioma"
        data-qa="modal-content"
      >
        <div className={styles.Message}>
          <p>¡Bienvenido a Elevations!</p>
          <p>
            Notamos que su navegador está configurado en español. Hemos traducido gran parte de
            nuestro sitio web en español, pero algunas partes de nuestro sitio web permanecen en
            inglés en este momento.
          </p>

          <p>
            Si desea abrir una cuenta o solicitar un producto en línea, tenga en cuenta que nuestras
            solicitudes en línea y los documentos relacionados están disponibles solo en inglés.
            Para obtener asistencia en español con una solicitud, por favor contáctenos.
          </p>

          <p>
            Aviso: Traducciones en español se proporcionan como cortesía. En caso de cualquier
            discrepancia o variación entre inglés y español de divulgaciones o materiales que se
            haya proporcionado, la versión en inglés controlará y sustituirá cualquier versión en
            español para todos los fines, incluidos los fines legales.
          </p>

          <p>¿Le gustaría continuar en español?</p>
        </div>

        <Stack distribution="center">
          <Stack.Item className={styles.DismissButton}>
            <Button variant="lightSecondary" onClick={onCancel}>
              No, use inglés
            </Button>
          </Stack.Item>

          <Stack.Item>
            <Button ref={confirmButtonRef} variant="light" onClick={onConfirm}>
              Sí, continúe a español
            </Button>
          </Stack.Item>
        </Stack>
      </DialogContent>
    </DialogOverlay>
  )
}

SpanishAvailable.propTypes = {
  isOpen: PropTypes.bool,
  onDismiss: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export { SpanishAvailable }
export default SpanishAvailable
