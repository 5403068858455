import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import capitalize from 'lodash/capitalize'
import * as styles from './Col.module.scss'

const DEVICE_SIZES = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs']

/**
 * A grid column.  Use props 'xxl', 'xl', 'lg', 'md', 'sm', 'xs' in the range of 1-12 to set
 * how much horizontal space will be used at a particular media width.  Note: DO NOT assign a class
 * to this component that adjusts horizontal spacing (e.g. margin, padding), since that will alter
 * the proper functioning of the breakpoints.  Instead, assign that class to a <div> tag inside the
 * <Col> element.
 */
const Col = forwardRef(({ element: Element = 'div', className, ...props }, ref) => {
  const prefix = 'col'
  const spans = []
  const classes = []

  DEVICE_SIZES.forEach(brkPoint => {
    const propValue = props[brkPoint]

    // eslint-disable-next-line no-param-reassign
    delete props[brkPoint]

    let span
    let offset
    let order
    if (propValue != null && typeof propValue === 'object') {
      ;({ span = true, offset, order } = propValue)
    } else {
      span = propValue
    }

    const infix = capitalize(brkPoint !== 'xs' ? `${capitalize(brkPoint)}` : '')

    if (span != null)
      spans.push(span === true ? styles[`${prefix}${infix}`] : styles[`${prefix}${infix}${span}`])
    if (order != null) classes.push(styles[`order${infix}${order}`])
    if (offset != null) classes.push(styles[`offset${infix}${offset}`])
  })

  if (!spans.length) {
    spans.push(styles[prefix]) // plain 'col'
  }

  return (
    <Element
      ref={ref}
      className={classNames(className, ...spans, ...classes)}
      data-qa="grid-column"
      {...props}
    />
  )
})

const colSize = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.number,
  PropTypes.string,
  PropTypes.oneOf(['auto']),
])

const stringOrNumber = PropTypes.oneOfType([PropTypes.number, PropTypes.string])

const column = PropTypes.oneOfType([
  colSize,
  PropTypes.shape({
    size: colSize,
    order: stringOrNumber,
    offset: stringOrNumber,
  }),
])

Col.propTypes = {
  element: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
  xs: column,
  sm: column,
  md: column,
  lg: column,
  xl: column,
  xxl: column,
}

export { Col }
export default Col
