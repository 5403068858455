// extracted by mini-css-extract-plugin
export var DialogOverlay = "ApplyModal-module--DialogOverlay--2A7K2";
export var DialogContent = "ApplyModal-module--DialogContent--2s8OI";
export var heading = "ApplyModal-module--heading--QIbqg";
export var Oval = "ApplyModal-module--Oval--QfmdO";
export var CtaButton = "ApplyModal-module--CtaButton--1cI7T";
export var HeadingStyle = "ApplyModal-module--HeadingStyle--2CtfE";
export var TeamMemberModalBody = "ApplyModal-module--TeamMemberModalBody--3cmn-";
export var Section = "ApplyModal-module--Section--3U_aE";
export var SectionTitle = "ApplyModal-module--SectionTitle--3D-rG";
export var Cta2Button = "ApplyModal-module--Cta2Button--1rfL7";
export var buttonContainer = "ApplyModal-module--buttonContainer--CQwSr";
export var titleContainer = "ApplyModal-module--titleContainer--2Xgtf";