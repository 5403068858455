import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { TextField } from 'components/textfield'
import { Heading } from '../typography/Heading'
import { RichText } from '../rich-text'
import { Button } from '../button'
import '@reach/dialog/styles.css'
import * as styles from './CaptureModal.module.scss'
import CloseImage from '../../images-global/close.svg'

const CaptureModal = ({ open, onDismiss, cta1href }) => {
  const [t] = useTranslation()
  const imgSrcSets = [
    {
      height: 220,
    },
  ]

  const handleSubmit = async event => {
    event.preventDefault()
    const form = event.currentTarget
    console.log(form.email.value)
    const modalForm = document.getElementById('meridianlink-marketing-recapture')
    if (modalForm) {
      // Create a hidden input field for the URL
      const hiddenInput = document.createElement('input')
      hiddenInput.type = 'hidden'
      hiddenInput.name = 'submission_url'
      hiddenInput.value = window.location.href
      modalForm.appendChild(hiddenInput)
      let redirectUrl = cta1href
      const lenderref = document.getElementById('lenderref').value
      const scenario = document.getElementById('scenario').value
      redirectUrl = `${redirectUrl}?lenderref=${lenderref}`
      redirectUrl = `${redirectUrl}&scenario=${scenario}`
      const URL = 'https://formspree.io/f/xrbzgokz/'
      try {
        const payload = {
          email: form.email.value,
          lenderref,
          submission_url: window.location.href,
          scenario,
        }
        console.log('payload', payload)
        const response = await axios.post(URL, payload)
        window.location = cta1href
      } catch (error) {
        console.error('There was an error submitting the form data', error)
        window.location = cta1href
      }
    }
  }

  return (
    <DialogOverlay className={styles.DialogOverlay} isOpen={open} onDismiss={onDismiss}>
      <DialogContent
        className={styles.DialogContent}
        aria-label={t('CaptureModal.ariaLabel', {
          defaultValue: `Are you an existing member?`,
        })}
        data-qa="apply-modal"
      >
        <Fragment>
          <div className={styles.CaptureModalBody}>
            <form
              id="meridianlink-marketing-recapture"
              onSubmit={handleSubmit}
              action="https://app.consumer.meridianlink.com/xa/xpressapp.aspx"
              method="POST"
              className="form form--secondary"
              data-redirect-url="https://app.consumer.meridianlink.com/xa/xpressapp.aspx"
            >
              <input type="hidden" id="lenderref" name="lenderref" value="ElevationCU81916xx22" />
              <input type="hidden" id="scenario" name="scenario" value="" />
              <div className={styles.formGroup}>
                <TextField
                  id="email"
                  placeholder="Email"
                  maxLength="80"
                  name="email"
                  size="20"
                  type="email"
                  required
                  label="Let's start with your email address"
                />
              </div>
              <div className={styles.submitButton}>
                <Button key="submit" type="submit" alt="Submit">
                  Let's Go!
                </Button>
              </div>
            </form>
          </div>
        </Fragment>
      </DialogContent>
    </DialogOverlay>
  )
}

CaptureModal.propTypes = {
  onDismiss: PropTypes.func,
  open: PropTypes.bool,
  cta1href: PropTypes.string,
  cta1text: PropTypes.string,
  cta1Alt: PropTypes.string,
  cta2href: PropTypes.string,
  cta2text: PropTypes.string,
  cta2Alt: PropTypes.string,
}

export { CaptureModal }
export default CaptureModal
