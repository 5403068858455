import { Fragment, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import normalizePagePath from '../../utilities/normalizePagePath'

import { SpanishAvailable } from './SpanishAvailable'
import { SpanishIncomplete } from './SpanishIncomplete'

const LocaleSwitcherModal = ({ pageContext }) => {
  const [_t, i18n] = useTranslation()
  const [shown, setShown] = useState(false)

  useEffect(() => {
    // if there's a remembered language and the given language is still supported,
    // perform a redirect
    const currentAppLanguage = i18n.language
    const currentUserLanguage =
      navigator.language ||
      navigator.userLanguage ||
      navigator.languages[0] ||
      i18n.options.fallbackLng[0]
    const preferredLanguage = window.localStorage.getItem('preferredLanguage') || ''
    const acknowledged = Boolean(window.localStorage.getItem('spanishContentDisclaimerAck'))

    if (!currentAppLanguage.startsWith(preferredLanguage.slice(0, 2)) && preferredLanguage) {
      // redirect to the preferred language
      navigate(
        normalizePagePath(pageContext.pathname, {
          locale: preferredLanguage,
          defaultLocale: i18n.options.fallbackLng[0],
        }),
        { replace: true }
      )
    } else if (
      !currentAppLanguage.startsWith('es') &&
      currentUserLanguage.startsWith('es') &&
      !acknowledged
    ) {
      // show prompt
      setShown('available')
    } else if (currentAppLanguage.startsWith('es') && !acknowledged) {
      // show prompt
      setShown('incomplete')
    }
  }, [i18n])

  const dismiss = useCallback(() => {
    setShown(false)
  })

  const handleUseEnglish = useCallback(() => {
    window.localStorage.setItem('preferredLanguage', 'en-US')
    window.localStorage.setItem('spanishContentDisclaimerAck', 'true')
    setShown(false)
  }, [])

  const handleContinueToSpanish = useCallback(() => {
    window.localStorage.setItem('preferredLanguage', 'es-US')
    window.localStorage.setItem('spanishContentDisclaimerAck', 'true')
    navigate(
      normalizePagePath('/', {
        locale: 'es-US',
        defaultLocale: i18n.options.fallbackLng[0],
      })
    )
    setShown(false)
  }, [])

  const handleContinueToEnglish = useCallback(() => {
    window.localStorage.setItem('preferredLanguage', 'en-US')
    window.localStorage.setItem('spanishContentDisclaimerAck', 'true')
    navigate(
      normalizePagePath('/', {
        locale: 'en-US',
        defaultLocale: i18n.options.fallbackLng[0],
      })
    )
    setShown(false)
  }, [])

  const handleUseSpanish = useCallback(() => {
    window.localStorage.setItem('preferredLanguage', 'es-US')
    window.localStorage.setItem('spanishContentDisclaimerAck', 'true')
    setShown(false)
  }, [])

  return (
    <Fragment>
      <SpanishAvailable
        isOpen={shown === 'available'}
        onDismiss={dismiss}
        onCancel={handleUseEnglish}
        onConfirm={handleContinueToSpanish}
      />
      <SpanishIncomplete
        isOpen={shown === 'incomplete'}
        onDismiss={dismiss}
        onCancel={handleContinueToEnglish}
        onConfirm={handleUseSpanish}
      />
    </Fragment>
  )
}

LocaleSwitcherModal.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export { LocaleSwitcherModal }
export default LocaleSwitcherModal
