import some from 'lodash/some'
import trimEnd from 'lodash/trimEnd'

const normalizeTrailingSlash = url => trimEnd(url, '/')

export const findTopLevelNavItem = (currentPagePath, navItems) => {
  for (let i = 0; i < navItems.length; i += 1) {
    const navItem = navItems[i]

    if (
      normalizeTrailingSlash(navItem.urlPath) === normalizeTrailingSlash(currentPagePath) ||
      some(
        navItem.children,
        subNavItem =>
          normalizeTrailingSlash(subNavItem.urlPath) === normalizeTrailingSlash(currentPagePath)
      )
    ) {
      return navItem
    }
  }
}

export const checkShouldShowSubNav = (
  showSubNav,
  showSubnavOnScroll,
  currentPage,
  observerHasInitialized,
  inView
) => {
  let shouldShowSubnav = false
  if (showSubNav) {
    if (currentPage) {
      shouldShowSubnav = true
    }

    if (observerHasInitialized && !inView) {
      shouldShowSubnav = true
    }
  }

  if (showSubnavOnScroll) {
    if (observerHasInitialized && !inView) {
      shouldShowSubnav = true
    }
  }
  return shouldShowSubnav
}
