import { Fragment } from 'react'
import PropTypes from 'prop-types'

const CCPATable = ({ theme }) => {
  return (
    <Fragment>
      <table className={theme}>
        <caption>California Consumer Privacy Act Disclosure for California</caption>

        <thead>
          <tr>
            <th scope="col">Categories of information collected by the Credit Union</th>
            <th scope="col">Pieces of Personal Information Collected</th>
            <th scope="col">Purpose for Collection of Information</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Personal information provided by you for account opening</td>
            <td>
              Name, address, Tax ID Number, Date of Birth, phone number, email address, employment,
              photo ID, income
            </td>
            <td>
              Required for account opening, prevention of identity theft and fraud, and to meet
              regulatory requirements for member due diligence
            </td>
          </tr>
          <tr>
            <td>Personal information provided by you for loan requests</td>
            <td>Information regarding assets, tax records, debts</td>
            <td>Required for loan processing and underwriting</td>
          </tr>
          <tr>
            <td>
              Personal information provided by you when using social media, or software and online
              products and services offered by the Credit Union
            </td>
            <td>
              Email address, name, account information, personal finance information, IP address
            </td>
            <td>To provide information, products and services to you</td>
          </tr>
          <tr>
            <td>Consumer reports including credit reports</td>
            <td>Account balances, payment history, credit history</td>
            <td>Prevention of identity theft and fraud, and to evaluate loan requests</td>
          </tr>
          <tr>
            <td>Credit and debit card activity</td>
            <td>Transaction amount, location, time, and merchant identity </td>
            <td>Enable fraud monitoring and account protection</td>
          </tr>
          <tr>
            <td>Online banking activity</td>
            <td>IP address, login patterns</td>
            <td>Enable fraud monitoring and account protection</td>
          </tr>
        </tbody>
      </table>

      <table>
        <thead>
          <tr>
            <th scope="col">
              Categories of third parties with whom information is shared and disclosed by the
              Credit Union
            </th>
            <th scope="col">Pieces of Personal Information Shared</th>
            <th scope="col">Purpose for Sharing of Information</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Third party product and service providers such as online banking, mortgages, cards,
              and money transfer services
            </td>
            <td>
              Name, account information including account number, transaction history and loan
              information, Tax ID Number, address, phone number, email address
            </td>
            <td>To provide products and services selected by you</td>
          </tr>
          <tr>
            <td>Data processors</td>
            <td>
              Name, account information including account number, transaction history and loan
              information, Tax ID Number, photo ID info, address, phone number, email address,
              employer
            </td>
            <td>
              To perform business functions such as data analytics and required account monitoring
            </td>
          </tr>
          <tr>
            <td>Consumer reporting agencies including credit reporting agencies</td>
            <td>
              Name, account number and loan information, Tax ID Number, address, employer, loan
              payment history
            </td>
            <td>Required consumer reporting</td>
          </tr>
          <tr>
            <td>Marketing</td>
            <td>Name, address, phone number, email address</td>
            <td>To inform you about credit union products, services and events</td>
          </tr>
          <tr>
            <td>Governmental agencies</td>
            <td>
              Name, account information including account number, tax information such as dividends
              earns and interest paid, loan information, Tax ID Number, photo ID info, address,
              phone number, email address, Census and Demographic Data
            </td>
            <td>Required by State and Federal Laws</td>
          </tr>
        </tbody>
      </table>

      <table>
        <thead>
          <tr>
            <th scope="col">
              Categories of third parties with whom information was sold by the Credit Union
            </th>
            <th scope="col">Pieces of Personal Information Sold</th>
            <th scope="col">Purpose for Selling of Information</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Third party insurance providers</td>
            <td>Name, address, phone number, email address, and date of birth</td>
            <td>To compliment Credit Union core product offerings with insurance products</td>
          </tr>
          <tr>
            <td colSpan="3" style={{ textAlign: 'left' }}>
              <strong>Note:</strong> The Credit Union does not generally sell member data and does
              not contemplate selling in the future. The Credit Union has in the past entered into
              an agreement to share information for the purposes of making insurance products
              available to our members as part of our financial planning services, but the Credit
              Union no longer participates in sharing data for that purpose.{' '}
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  )
}

CCPATable.propTypes = {
  theme: PropTypes.string,
}

export default CCPATable
