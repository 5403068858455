import { Fragment, useEffect, useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import qs from 'qs'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGlobe,
  faMapSigns,
  faSignIn,
  faCommentAlt,
  faQuestionCircle,
  faClipboardList,
  faClipboard,
} from '@fortawesome/pro-regular-svg-icons'
import { faCalculatorAlt } from '@fortawesome/pro-light-svg-icons'
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import { Modal } from '../../modal'
import { Link } from '../../typography/Link'
import { NavLink } from '../HamburgerMenu/components/NavLink'
import * as styles from './NavigationPanel.module.scss'
import logoImage from '../../../images-global/ecuLogoIcon.svg'
import questionMark from '../../../images-global/QuestionMark.svg'

const NavigationPanel = ({
  menu,
  location,
  pageContext,
  isBusiness,
  onClickOutside,
  buttonRef,
}) => {
  const [t, i18n] = useTranslation()
  const [openSubmenuId, setOpenSubmenuId] = useState(null)
  const [searchIsActive, setSearchIsActive] = useState(false)
  const [subMenu, setSubMenu] = useState(null)
  const tabIndex = openSubmenuId !== null ? '-1' : '0'
  const navRootRef = useRef(null)
  const ref = useRef(null)
  const prevExpandedButtonRef = useRef(null)
  const chosenMenu = isBusiness ? menu.business_panel : menu.personal_panel

  useEffect(() => {
    const handleOutsideClick = event => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        // eslint-disable-next-line
        onClickOutside && onClickOutside()
      }
    }
    document.addEventListener('click', handleOutsideClick, true)
    return () => {
      document.removeEventListener('click', handleOutsideClick, true)
    }
  }, [onClickOutside])

  const handleDismiss = useCallback(() => {
    onClickOutside()
  }, [])

  useEffect(() => {
    if (!navRootRef.current) {
      return
    }

    if (openSubmenuId) {
      const newNav = menu.personal_panel.find(tmpMenu => {
        return tmpMenu.title === openSubmenuId
      })
      setSubMenu(newNav)
    }

    const navRootEl = navRootRef.current
    const expandedButton = navRootEl.querySelector('button[aria-expanded="true"]')
    const prevExpandedButton = prevExpandedButtonRef.current

    if (openSubmenuId && expandedButton) {
      const secondLevelButtons = expandedButton.nextElementSibling.querySelectorAll('li > button')
      if (secondLevelButtons.length > 0) {
        secondLevelButtons[0].focus()
      }
      prevExpandedButtonRef.current = expandedButton
    } else if (!openSubmenuId && prevExpandedButton) {
      prevExpandedButton.focus()
    }
  }, [openSubmenuId, subMenu])

  return (
    <div ref={ref} className={styles.panelContainer}>
      <div className={isBusiness ? styles.leftContainerBusiness : styles.leftContainer}>
        <div className={styles.leftTopCard}>
          <a
            className={styles.cardAnchor}
            href={isBusiness ? '/business-banking/checking-savings/#section-2' : '/about/why-ecu'}
          >
            <div className={styles.leftUpperCardContainer}>
              <img src={logoImage} alt="" />
            </div>
            <div className={styles.rightUpperCardContainer}>
              <h3>{t('Header.navPanelTopCardHeader', { defaultValue: 'BECOME A MEMBER' })}</h3>
              <p>
                {t('Header.navPanelTopCard', {
                  defaultValue:
                    'It all starts with your membership. Why not become a member today and start enjoying the benefits?',
                })}
              </p>
            </div>
          </a>
        </div>
        <div className={styles.leftLowerCard}>
          <a className={styles.cardAnchorLower} href="/resources">
            <div className={styles.leftUpperCardContainer}>
              <img src={questionMark} alt="" />
            </div>
            <div className={styles.rightUpperCardContainer}>
              <h3>{t('Header.navPanelLowerCardHeader', { defaultValue: 'RESOURCES & FAQs' })}</h3>
              <p>
                {t('Header.navPanelLowerCard', {
                  defaultValue:
                    'Have questions about online banking or our products and services? Check out our Resources page.',
                })}
              </p>
            </div>
          </a>
        </div>
        <div className={styles.leftColFooter}>
          <div className={styles.panelFooterLeftCol}>
            <ul data-qa="hamburger-menu-util-nav">
              <li>
                <FontAwesomeIcon
                  aria-hidden
                  aria-label={t('HamburgerMenu.locationIconAriaLabel', {
                    defaultValue: 'Location icon',
                  })}
                  icon={faMapSigns}
                  className={styles.LinkIcon}
                />
                <Link to="/branch-locations" tabIndex={tabIndex} light>
                  {t('HamburgerMenu.locationsLinkLabel', { defaultValue: 'Locations' })}
                </Link>
              </li>
              <li>
                <FontAwesomeIcon
                  aria-hidden
                  aria-label={t('HamburgerMenu.calculatorIconAriaLabel', {
                    defaultValue: 'Calculator icon',
                  })}
                  icon={faCalculatorAlt}
                  className={styles.LinkIcon}
                />
                <Link to="/calculators" tabIndex={tabIndex} light>
                  {t('HamburgerMenu.calculatorsLinkLabel', { defaultValue: 'Calculators' })}
                </Link>
              </li>
              <li>
                <FontAwesomeIcon
                  aria-hidden
                  aria-label={t('HamburgerMenu.contactUsIconAriaLabel', {
                    defaultValue: 'Contact Us icon',
                  })}
                  icon={faCommentAlt}
                  className={styles.LinkIcon}
                />
                <Link to="/contact-us" tabIndex={tabIndex} light>
                  {t('HamburgerMenu.contactUsLinkLabel', { defaultValue: 'Contact Us' })}
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <ul data-qa="hamburger-menu-util-nav-right-col">
              <li>
                <FontAwesomeIcon
                  aria-hidden
                  aria-label={t('HamburgerMenu.careerIconAriaLabel', {
                    defaultValue: 'Career icon',
                  })}
                  icon={faClipboardList}
                  className={styles.LinkIcon}
                />
                <Link to="/careers" tabIndex={tabIndex} light>
                  {t('HamburgerMenu.careerLinkLabel', { defaultValue: 'Careers' })}
                </Link>
              </li>
              <li>
                <FontAwesomeIcon
                  aria-hidden
                  aria-label={t('HamburgerMenu.globeIconAriaLabel', {
                    defaultValue: 'Globe icon',
                  })}
                  icon={faGlobe}
                  className={styles.LinkIcon}
                />
                {i18n.language === 'es-US' ? (
                  <Link
                    to={pageContext.pathname}
                    tabIndex={tabIndex}
                    light
                    alt={t('HamburgerMenu.englishButtonLongLabel', {
                      defaultValue: 'English',
                    })}
                    onMouseDown={() => {
                      window.localStorage.setItem('preferredLanguage', 'en-US')
                    }}
                    locale="en-US"
                    lang="en-US"
                  >
                    {t('HamburgerMenu.englishButtonLongLabel', { defaultValue: 'English' })}
                  </Link>
                ) : (
                  <Link
                    to={pageContext.pathname}
                    light
                    tabIndex={tabIndex}
                    alt={t('Header.spanishButtonLongLabel', { defaultValue: 'Español' })}
                    onMouseDown={() => {
                      window.localStorage.setItem('preferredLanguage', 'es-US')
                    }}
                    locale="es-US"
                    lang="es-US"
                  >
                    {t('HamburgerMenu.spanishButtonLongLabel', { defaultValue: 'Español' })}
                  </Link>
                )}
              </li>
              <li>
                <FontAwesomeIcon
                  aria-hidden
                  aria-label={t('HamburgerMenu.loginIconAriaLabel', {
                    defaultValue: 'Login icon',
                  })}
                  icon={faSignIn}
                  className={styles.LinkIcon}
                />
                <Link
                  to={`/login${qs.stringify(isBusiness ? { action: 'business' } : {}, {
                    addQueryPrefix: true,
                  })}`}
                  tabIndex={tabIndex}
                  alt={
                    isBusiness
                      ? t('HamburgerMenu.businessLoginButtonAriaLabel', {
                          defaultValue: 'Business Log In',
                        })
                      : undefined
                  }
                  light
                >
                  {t('HamburgerMenu.loginButtonLabel', { defaultValue: 'Log In' })}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div
          className={classNames(
            styles.HamburgerMenuInner,
            openSubmenuId !== null && styles.HamburgerMenuInnerOpen
          )}
          data-qa="hamburger-menu-container"
        >
          <nav
            role="navigation"
            aria-label={t('HamburgerMenu.primaryMenuAriaLabel', {
              defaultValue: 'Primary Navigation',
            })}
          >
            <ul data-qa="hamburger-menu-nav" ref={navRootRef}>
              {chosenMenu.map(menuItem => (
                <NavLink
                  key={menuItem.title}
                  label={menuItem.title}
                  to={menuItem.urlPath}
                  isTopLevel
                  openSubmenuId={openSubmenuId}
                  setSubnavOpen={setOpenSubmenuId}
                  subNavId={menuItem.title}
                  dark
                  closeMenu={handleDismiss}
                >
                  {menuItem.children.length > 0 &&
                    menuItem.children.map(child => (
                      <NavLink
                        key={child.title}
                        label={child.title}
                        to={child.urlPath}
                        openSubmenuId={openSubmenuId}
                        setSubnavOpen={setOpenSubmenuId}
                        subNavId={menuItem.title}
                        dark
                        closeMenu={handleDismiss}
                      />
                    ))}
                </NavLink>
              ))}
            </ul>
          </nav>
        </div>
        <div className={styles.rightContainerLower}>
          <Link to="/about" onClick={onClickOutside} className={styles.bottomLink}>
            {t('Header.navPanelAbout', { defaultValue: 'About Elevations' })}
            <FontAwesomeIcon aria-hidden icon={faChevronRight} className={styles.BottomLinkIcon} />
          </Link>
          <Link to="/foundation" onClick={onClickOutside} className={styles.bottomLink}>
            {t('Header.navPanelFoundation', { defaultValue: 'Local Change Foundation' })}
            <FontAwesomeIcon aria-hidden icon={faChevronRight} className={styles.BottomLinkIcon} />
          </Link>
        </div>
      </div>
    </div>
  )
}

NavigationPanel.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  menu: PropTypes.object.isRequired,
}

export { NavigationPanel }
export default NavigationPanel
