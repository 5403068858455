/* eslint-disable react/no-danger */

// Snippeted results contain search term highlights with html and are
// 100% safe and santitized, so we dangerously set them here

import PropTypes from 'prop-types'
import classNames from 'classnames'
import truncate from 'lodash/truncate'
import { UnstyledLink } from '../../../typography/UnstyledLink'
import { getRaw, getSnippet, htmlEscape, getUrlSanitizer } from './utils'
import * as styles from './SearchResult.module.scss'

const SearchResult = ({ className, result, onClickLink }) => {
  const title =
    getSnippet(result, 'title') || htmlEscape(truncate(getRaw(result, 'title'), { length: 100 }))
  const body =
    getSnippet(result, 'body') || htmlEscape(truncate(getRaw(result, 'body'), { length: 100 }))
  const url = getUrlSanitizer(URL, window.location)(getRaw(result, 'url'))
  const normalizedUrl = String(url).replace(/https:\/\/(www.)?elevationscu.com?/, '')

  return (
    <li className={classNames(styles.SearchResult, className)}>
      <UnstyledLink
        className={styles.SearchResultLink}
        to={normalizedUrl}
        onClick={onClickLink}
        locale={false}
        aria-label={title}
      >
        <span className={styles.SearchResultTitle} dangerouslySetInnerHTML={{ __html: title }} />
        <p
          dangerouslySetInnerHTML={{
            __html: body,
          }}
        />
      </UnstyledLink>
    </li>
  )
}

SearchResult.propTypes = {
  className: PropTypes.string,
  result: PropTypes.object,
  onClickLink: PropTypes.func,
}

export { SearchResult }
export default SearchResult
