const VALID_PROTOCOLS = ['http:', 'https:']

export function getUrlSanitizer(URLParser, currentLocation) {
  // This function is curried so that dependencies can be injected and don't need to be mocked in tests.
  return url => {
    let parsedUrl = {}

    try {
      // Attempts to parse a URL as relative
      parsedUrl = new URLParser(url, currentLocation)
      // eslint-disable-next-line no-empty
    } catch (e) {}

    return VALID_PROTOCOLS.includes(parsedUrl.protocol) ? url : ''
  }
}

function getFieldType(result, field, type) {
  if (result[field]) return result[field][type]
}

export function getRaw(result, field) {
  return getFieldType(result, field, 'raw')
}

export function getSnippet(result, field) {
  return getFieldType(result, field, 'snippet')
}

export function htmlEscape(str) {
  if (!str) return ''

  return String(str)
    .replace(/&/g, '&amp;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
}

export function getEscapedField(result, field) {
  // Fallback to raw values here, because non-string fields
  // will not have a snippet fallback. Raw values MUST be html escaped.
  const safeField = getSnippet(result, field) || htmlEscape(getRaw(result, field))
  return Array.isArray(safeField) ? safeField.join(', ') : safeField
}
