import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import uniq from 'lodash/uniq'
import SiteSearchAPIConnector from '@elastic/search-ui-site-search-connector'
import { SearchProvider } from '@elastic/react-search-ui'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'

import { Heading } from '../../typography/Heading'
import { boostQuery, saveRecentSearches, getRecentSearches, isValidSearchTerm } from './utils'
import { SearchInput } from './components/SearchInput'
import { SearchResults } from './components/SearchResults'
import { ResetOnUnload } from './components/ResetOnUnload'

import * as styles from './SearchBar.module.scss'

const MAX_RECENT_SEARCHES = 5

const siteSearchConnector = new SiteSearchAPIConnector({
  documentType: 'page',
  engineKey: process.env.ELASTIC_SITE_SEARCH_ENGINE_KEY,
  beforeSearchCall: (queryOptions, next) => next(boostQuery(queryOptions)),
})

const SearchBar = ({ showResults, autoFocus, onChange, onClickLink }) => {
  const [t, i18n] = useTranslation()

  const topSearchesResponse = useStaticQuery(graphql`
    query SuggestedSearchesQuery {
      allContentfulSuggestedSearches {
        nodes {
          node_locale
          terms
        }
      }
    }
  `)

  const topSearchesAllLocales = topSearchesResponse.allContentfulSuggestedSearches.nodes.filter(
    x => x.node_locale === i18n.language
  )

  const topSearches = topSearchesAllLocales[0].terms
  const [recentSearches, setRecentSearches] = useState([])

  useEffect(() => {
    setRecentSearches(getRecentSearches())
  }, [])

  const addRecentSearch = useCallback(searchTerm => {
    if (isValidSearchTerm(searchTerm)) {
      setRecentSearches(prevRecentSearches => {
        const nextRecentSearches = uniq([searchTerm, ...prevRecentSearches]).slice(
          0,
          MAX_RECENT_SEARCHES
        )
        saveRecentSearches(nextRecentSearches)
        return nextRecentSearches
      })
    }
  }, [])

  const [config] = useState(() => ({
    onSearch: (state, queryConfig, next) => {
      if (typeof onChange === 'function') {
        onChange({ ...state })
      }

      addRecentSearch(state.searchTerm)

      const promise = next(state, queryConfig)

      promise.then(newState => {
        if (typeof onChange === 'function') {
          onChange({ ...state, ...newState })
        }
      })

      return promise
    },
    apiConnector: siteSearchConnector,
    searchQuery: {
      search_fields: {
        title: {},
        sections: {},
        body: {},
      },
      result_fields: {
        title: {
          snippet: {
            size: 100,
            fallback: true,
          },
        },
        url: {
          raw: {},
        },
        locale: {
          raw: {},
        },
        body: {
          snippet: {
            size: 100,
            fallback: true,
          },
        },
      },
    },
    initialState: {
      current: 1,
      filters: [],
      resultsPerPage: 7,
      searchTerm: '',
      sortDirection: undefined,
      sortField: undefined,
    },
    hasA11yNotifications: true,
    a11yNotificationMessages: {
      searchResults: ({ start, end, totalResults, searchTerm }) =>
        t('SearchBar.searchInProgress', {
          defaultValue:
            'Searching for "{{searchTerm}}". Showing {{start}} to {{end}} results out of {{totalResults}}.',
          searchTerm,
          start,
          end,
          totalResults,
        }),
    },
    alwaysSearchOnInitialLoad: false,
  }))

  const handleInputClear = useCallback(() => {
    if (typeof onChange === 'function') {
      onChange({ searchTerm: '' })
    }
  }, [])

  return (
    <SearchProvider config={config}>
      <div>
        <ResetOnUnload />

        <Heading element="h1" size={1} className={styles.SearchHeading}>
          {t('SearchBar.search', { defaultValue: 'Search' })}
        </Heading>

        <SearchInput {...{ handleInputClear, autoFocus }} />

        {showResults && (
          <SearchResults
            topSearches={topSearches}
            recentSearches={recentSearches}
            onClickLink={onClickLink}
          />
        )}
      </div>
    </SearchProvider>
  )
}

SearchBar.propTypes = {
  autoFocus: PropTypes.bool,
  showResults: PropTypes.bool,
  onChange: PropTypes.func,
  onClickLink: PropTypes.func,
}

SearchBar.defaultProps = {
  showResults: true,
}

export { SearchBar }
export default SearchBar
