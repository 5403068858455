import PropTypes from 'prop-types'

const RecVehicleLoanRates = ({ theme }) => {
  return (
    <table className={theme}>
      <caption>Motorcycle and Recreational Vehicle Loan Rates</caption>
      <thead>
        <tr>
          <th scope="col">Terms</th>
          <th scope="col">Vehicle Type</th>
          <th scope="col">
            APR Starting At<sup>*</sup>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">Up to 63 Months</th>
          <td>Titled Loans: Motorcycle, Motorhome, Travel Trailer</td>
          <td>
            8.45%<sup>†</sup>
          </td>
        </tr>
        <tr>
          <th scope="row">Up to 63 Months</th>
          <td>Untitled Loans: Motorcycle, ATV, Personal Watercraft, Boat, Snowmobile</td>
          <td>
            8.95%<sup>†</sup>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

RecVehicleLoanRates.propTypes = {
  theme: PropTypes.string,
}

export default RecVehicleLoanRates
