import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import * as styles from './Item.module.scss'

const Item = ({ children, className, _customBullet = 'none', ...props }) => {
  const [t] = useTranslation()
  const classes = classNames(styles.Item, _customBullet !== 'none' && styles.custom, className)

  return (
    <li className={classes} data-qa="list-item" {...props}>
      {_customBullet === 'check' && (
        // It's important that the custom bullet is visible to the screen reader
        // otherwise it won't treat the list as a list.
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="false"
          className={classNames(styles.Symbol, styles.check)}
        >
          <path
            d="M5.43431 13.6971L0.234305 8.49706C-0.0781016 8.18465 -0.0781016 7.67812 0.234305 7.36568L1.36565 6.23431C1.67806 5.92187 2.18462 5.92187 2.49703 6.23431L6 9.73725L13.503 2.2343C13.8154 1.9219 14.3219 1.9219 14.6344 2.2343L15.7657 3.36568C16.0781 3.67809 16.0781 4.18462 15.7657 4.49706L6.56569 13.6971C6.25325 14.0095 5.74672 14.0095 5.43431 13.6971V13.6971Z"
            fill="#009CDE"
          />
        </svg>
      )}

      {children}
    </li>
  )
}

Item.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  _customBullet: PropTypes.oneOf(['none', 'check']),
}

export { Item }
export default Item
