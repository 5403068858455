import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withSearch } from '@elastic/react-search-ui'

/**
 * This component resets search state when unmounted
 */
const ResetOnUnload = ({ reset }) => {
  useEffect(() => {
    return () => reset()
  }, [])

  return null
}

ResetOnUnload.propTypes = {
  children: PropTypes.node,
}

const mapContextToProps = context => ({
  reset: context.reset,
})

const ConnectedResetOnUnload = withSearch(mapContextToProps)(ResetOnUnload)

export { ConnectedResetOnUnload as ResetOnUnload }
export default ConnectedResetOnUnload
