import PropTypes from 'prop-types'
import { Link } from '../typography/Link'
import * as styles from './SkipLink.module.scss'

const SkipLinkTarget = 'content'

const SkipLink = ({ children }) => {
  return (
    <Link to={`#${SkipLinkTarget}`} className={styles.SkipLink} data-qa="skip-link" animate={false}>
      {children}
    </Link>
  )
}

SkipLink.propTypes = {
  children: PropTypes.node,
}

export { SkipLink, SkipLinkTarget }
export default SkipLink
