import { isValidElement } from 'react'

// See below for why this function is needed.
function hotReloadComponentCheck(AComponent, AnotherComponent) {
  const componentName = AComponent.name
  const anotherComponentName = AnotherComponent.displayName

  return (
    AComponent === AnotherComponent ||
    (Boolean(componentName) && componentName === anotherComponentName)
  )
}

// In development, we compare based on the name of the function because
// React Hot Loader proxies React components in order to make updates. In
// production we can simply compare the components for equality.
const isComponent =
  process.env.NODE_ENV === 'development'
    ? hotReloadComponentCheck
    : (AComponent, AnotherComponent) => AComponent === AnotherComponent

export function isElementOfType(element, Component) {
  if (element == null || !isValidElement(element) || typeof element.type === 'string') {
    return false
  }

  const { type } = element
  const Components = Array.isArray(Component) ? Component : [Component]

  return Components.some(AComponent => typeof type !== 'string' && isComponent(AComponent, type))
}

// Wraps `element` in `Component`, if it is not already an instance of
// `Component`. If `props` is passed, those will be added as props on the
// wrapped component. If `element` is null, the component is not wrapped.
export function wrapWithComponent(element, Component, props) {
  if (element == null) {
    return null
  }

  return isElementOfType(element, Component) ? element : <Component {...props}>{element}</Component>
}
