import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReachAlert from '@reach/alert'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { ScreenReaderText } from '../screen-reader-text'
import * as styles from './Alert.module.scss'

const Alert = ({
  variant = 'default',
  assertive,
  className,
  onDismiss,
  title,
  icon,
  children,
  ...props
}) => {
  const [t] = useTranslation()
  const classes = classNames(
    styles.Alert,
    variant === 'default' && styles.neutral,
    variant === 'error' && styles.error,
    variant === 'warning' && styles.warning,
    variant === 'success' && styles.success,
    className
  )

  const politenessLevel = assertive ? 'assertive' : 'polite'

  return (
    <ReachAlert type={politenessLevel} className={classes} data-qa="alert-container" {...props}>
      <div className={styles.iconAndTextWrapper}>
        <span className={styles.iconColumn}>{icon}</span>
        <span>
          {title && (
            <strong
              className={classNames(styles.Title, Boolean(icon) && styles.offsetLeft)}
              data-qa="alert-title"
            >
              {title}
            </strong>
          )}
          <span className={styles.divider}>|</span>
          <span className={styles.Message} data-qa="alert-message">
            {children}
          </span>
        </span>
      </div>
      {onDismiss && (
        <button
          type="button"
          className={styles.DismissButton}
          onClick={onDismiss}
          data-qa="alert-dismiss-button"
        >
          <ScreenReaderText data-qa="alert-dismiss-button-sr-text">
            {t('Alert.dismissButtonAriaLabel', { defaultValue: 'Dismiss alert' })}
          </ScreenReaderText>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}
    </ReachAlert>
  )
}

Alert.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'error', 'warning', 'success']),
  onDismiss: PropTypes.func,
  assertive: PropTypes.bool,
}

export { Alert }
export default Alert
