import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './Container.module.scss'

const Container = forwardRef(({ element: Element = 'div', className, ...props }, ref) => {
  const classes = classNames(styles.Container, className)
  return <Element ref={ref} className={classes} data-qa="grid-container" {...props} />
})

Container.propTypes = {
  element: PropTypes.elementType,
  className: PropTypes.string,
}

export { Container }
export default Container
