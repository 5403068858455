/**
 * Scatters the element in between each two adjoining elements
 * in the source array.
 *
 * Usage:
 * intersperse([1, 2, 3], 0)
 *   [1, 0, 2, 0, 3]
 *
 * @param {*} array source array
 * @param {*} element element to be scattered
 */
export function intersperse(array, element) {
  return array.reduce((acc, item, index) => {
    acc.push(item)

    if (index !== array.length - 1) {
      acc.push(
        // eslint-disable-next-line react/no-array-index-key
        <span key={`seperator-${index}`} aria-hidden role="presentation">
          {element}
        </span>
      )
    }

    return acc
  }, [])
}

export default intersperse
