import { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { Heading } from '../typography/Heading'
import { RichText } from '../rich-text'
import { Button } from '../button'
import '@reach/dialog/styles.css'
import * as styles from './ApplyModal.module.scss'
import CloseImage from '../../images-global/close.svg'

const ApplyModal = ({
  open,
  onDismiss,
  cta1text,
  cta1Alt,
  cta1href,
  cta2text,
  cta2Alt,
  cta2href,
}) => {
  const [t] = useTranslation()
  const imgSrcSets = [
    {
      height: 220,
    },
  ]

  return (
    <DialogOverlay className={styles.DialogOverlay} isOpen={open} onDismiss={onDismiss}>
      <DialogContent
        className={styles.DialogContent}
        aria-label={t('ApplyModal.ariaLabel', {
          defaultValue: `Are you an existing member?`,
        })}
        data-qa="apply-modal"
      >
        <Fragment>
          <div>
            <Heading element="h1" size={4} className={styles.heading}>
              {t('applyModal.heading', {
                defaultValue: 'Do you currently have an account or bank at Elevations?',
              })}
            </Heading>
            {/* <p>
              {t('applyModal.mainText', {
                defaultValue:
                  'Thank you for your interest in applying. Are you a member of Elevations Credit Union? If not, no problem. We’ll get you membership, as well.',
              })}
            </p> */}
          </div>

          <div className={styles.ApplyModalBody}>
            {cta1text && cta1href && (
              <div className={styles.buttonContainer} data-qa="apply-modal-bottom-container">
                <Button
                  className={styles.CtaButton}
                  url={cta1href}
                  data-qa="apply-modal-upper-button"
                  alt={cta1Alt}
                >
                  {t('applyModal.buttonOne', {
                    defaultValue: `Yes`,
                  })}
                </Button>

                {cta2href && cta2text && (
                  <Button
                    variant="secondary"
                    url={cta2href}
                    className={styles.Cta2Button}
                    alt={cta2Alt}
                    data-qa="apply-modal-lower-button"
                  >
                    {t('applyModal.buttonTwo', {
                      defaultValue: `No`,
                    })}
                  </Button>
                )}
              </div>
            )}
          </div>
        </Fragment>
      </DialogContent>
    </DialogOverlay>
  )
}

ApplyModal.propTypes = {
  onDismiss: PropTypes.func,
  open: PropTypes.bool,
  cta1href: PropTypes.string,
  cta1text: PropTypes.string,
  cta1Alt: PropTypes.string,
  cta2href: PropTypes.string,
  cta2text: PropTypes.string,
  cta2Alt: PropTypes.string,
}

export { ApplyModal }
export default ApplyModal
