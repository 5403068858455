import PropTypes from 'prop-types'
import classNames from 'classnames'
import { UnstyledLink } from '../UnstyledLink'
import * as styles from './Link.module.scss'

const Link = ({ className, light, nonGatsbySite, ...props }) => {
  return (
    <UnstyledLink
      className={classNames(styles.Link, light && styles.light, className)}
      nonGatsbySite={nonGatsbySite}
      {...props}
    />
  )
}

Link.propTypes = {
  className: PropTypes.string,
  light: PropTypes.bool,
  nonGatsbySite: PropTypes.bool,
  children: PropTypes.node,
}

export { Link }
export default Link
