// extracted by mini-css-extract-plugin
export var NavLink = "NavLink-module--NavLink--3IJR0";
export var NavLinkDark = "NavLink-module--NavLinkDark--2g2Vk";
export var SubLink = "NavLink-module--SubLink--2OvMk";
export var SubLinkDark = "NavLink-module--SubLinkDark--1l-aA";
export var Submenu = "NavLink-module--Submenu--1gwBU";
export var slideIn = "NavLink-module--slideIn--1DCzO";
export var SubmenuDark = "NavLink-module--SubmenuDark--1o2ua";
export var BackButton = "NavLink-module--BackButton--3TgBH";
export var BackButtonDark = "NavLink-module--BackButtonDark--3Q-cp";
export var SubmenuOpen = "NavLink-module--SubmenuOpen--2N8Bl";
export var SubmenuHeading = "NavLink-module--SubmenuHeading--3sV3L";
export var SubmenuHeadingDark = "NavLink-module--SubmenuHeadingDark--3IiVY";
export var RightChevron = "NavLink-module--RightChevron--3fWmH";
export var LeftChevron = "NavLink-module--LeftChevron--Jhqdn";
export var LeftChevronDark = "NavLink-module--LeftChevronDark--Syyyo";
export var fadeIn = "NavLink-module--fadeIn--1_cEX";