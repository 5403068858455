import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { SkipLink, SkipLinkTarget } from '../skip-link'
import { FeedbackButton } from '../modules/FeedbackButton'
import { Header } from '../header'
import { Footer } from '../footer'
import { Banner } from '../modules/Banner'
import { UnsupportedBrowserModal } from '../unsupported-browser-modal'
import { LocaleSwitcherModal } from '../locale-switcher-modal'
import { usePageAlert } from './hooks'
import '../../global.styles'

const Layout = ({ pageContext, location, children, menu, showSubnav, showSubnavOnScroll }) => {
  const [t] = useTranslation()
  const [pageAlert] = usePageAlert()

  return (
    <Fragment>
      <UnsupportedBrowserModal targetBrowsers={['IE']} />
      <LocaleSwitcherModal pageContext={pageContext} />

      <SkipLink>{t('Layout.skipLinkLabel', { defaultValue: 'Skip to main content' })}</SkipLink>

      {pageAlert && <Banner alert={pageAlert} />}

      <Header
        pageContext={pageContext}
        location={location}
        menu={menu}
        showSubnav={showSubnav}
        showSubnavOnScroll={showSubnavOnScroll}
      />
      {/* Tags the main content on the page and ignore all surrounding content when indexing */}
      <main role="main" id={SkipLinkTarget} data-swiftype-index="true">
        {children}
      </main>
      <Footer menu={menu} />
      {/* <FeedbackButton /> */}
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  menu: PropTypes.object,
  showSubnav: PropTypes.bool,
  showSubnavOnScroll: PropTypes.bool,
}

export { Layout }
export default Layout
