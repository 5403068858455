import { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import head from 'lodash/head'
import tail from 'lodash/tail'

const SUBSTITUTIONS_REGEX = /{{[\w-]+}}/g

const interpolate = (cell, variables = []) => {
  const variablesSet = {}
  variables.forEach(variable => {
    variablesSet[variable.key] = variable.value.value
  })

  const match = cell.match(SUBSTITUTIONS_REGEX)

  if (match) {
    let newCell = cell
    Array.from(match).forEach(substitution => {
      const varKey = substitution.slice(2, -2)
      newCell = newCell.replace(substitution, variablesSet[varKey])
    })
    return newCell
  }

  return cell
}

const parseTableConfig = (configJson, variables = []) => {
  const config = JSON.parse(configJson)

  for (let i = 0, noRows = config.rows.length; i < noRows; i += 1) {
    for (let j = 0, noCols = config.rows[i].length; j < noCols; j += 1) {
      config.rows[i][j] = interpolate(config.rows[i][j], variables || [])
    }
  }

  return config
}

const renderCell = (cell, rowIndex, colIndex, config) => {
  if (config.headerRow && rowIndex === 0) {
    return <th scope="col">{cell}</th>
  }
  if (config.headerCol && colIndex === 0) {
    return <th scope="row">{cell}</th>
  }

  return <td>{cell}</td>
}

const renderRow = (row, rowIndex, config) => {
  return <tr>{row.map((cell, colIndex) => renderCell(cell, rowIndex, colIndex, config))}</tr>
}

const DynamicTable = ({ config: rawConfig, caption, variables, labelledByID }) => {
  const config = useMemo(
    () => parseTableConfig(rawConfig.internal.content, variables || []),
    [rawConfig]
  )

  const tableProps = {}

  // If the table has neither a header row nor header column,
  // it's likely used for layout purposes and as such it needs
  // role="presentation".
  if (!config.headerRow && !config.headerCol) {
    tableProps.role = 'presentation'
  }

  return (
    <table
      {...tableProps}
      /* aria-labelledby={`tableHeaderID-${caption}`} // leaving this in because it might come back */
    >
      <caption>{caption}</caption>
      {config.headerRow ? (
        <Fragment>
          <thead>{renderRow(head(config.rows), 0, config)}</thead>
          <tbody>{tail(config.rows).map((row, index) => renderRow(row, index + 1, config))}</tbody>
        </Fragment>
      ) : (
        <tbody>{config.rows.map((row, index) => renderRow(row, index, config))}</tbody>
      )}
    </table>
  )
}

DynamicTable.propTypes = {
  caption: PropTypes.string,
  config: PropTypes.object,
  variables: PropTypes.arrayOf(PropTypes.object),
  labelledByID: PropTypes.string,
}

export default DynamicTable
