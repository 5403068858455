import { forwardRef, createElement, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useId } from '@reach/auto-id'
import useForkRef from '../../utilities/useForkRef'
import * as styles from './TextField.module.scss'

const TextField = forwardRef(
  (
    {
      type = 'text',
      id,
      multiline,
      label,
      disabled,
      error,
      visuallyHideLabel,
      onFocus,
      autoFocus,
      autoSelect,
      ...props
    },
    forwardedRef
  ) => {
    const innerRef = useRef(null)
    const setRef = useForkRef(innerRef, forwardedRef)
    const fieldId = useId(id)
    const inputId = `field-${fieldId}-input`
    const labelId = `field-${fieldId}-label`
    const errorId = `field-${fieldId}-error`

    const describedBy = []
    if (error) {
      describedBy.push(errorId)
    }
    // any help text or character counter (for multiline input) goes here

    const labelledBy = []
    // any prefix or suffix goes here
    labelledBy.unshift(labelId)

    // focus wrapper
    const handleFocus = useCallback(
      event => {
        if (onFocus) {
          onFocus(event)
        }

        if (autoSelect && innerRef.current) {
          innerRef.current.select()
        }
      },
      [innerRef]
    )

    useEffect(() => {
      // On initial load, if autoFocus was requested, autoFocus the field
      if (autoFocus && innerRef.current) {
        // Store the scroll position before focusing the field
        const x = window.scrollX
        const y = window.scrollY

        // Focus
        innerRef.current.focus()

        // Restore the scroll position
        window.scrollTo(x, y)
      }

      // On initial load, if both autoFocus AND autoSelect were requested, select the value on top of focusing the field
      if (autoFocus && autoSelect && innerRef.current) {
        innerRef.current.select()
      }
    }, [])

    const inputClasses = classNames(styles.Input, multiline && styles.multiline)
    const inputProps = {
      ref: setRef,
      type: !multiline ? type : undefined,
      id: inputId,
      className: inputClasses,
      disabled,
      onFocus: handleFocus,
      'aria-describedby': describedBy.length ? describedBy.join(' ') : undefined,
      'aria-labelledby': labelledBy.join(' '),
      'aria-invalid': Boolean(error),
      'data-qa': 'textfield',
      ...props,
    }

    // aria-multiline is only valid in the context of a textbox
    if (inputProps.type === 'text' || multiline) {
      inputProps['aria-multiline'] = Boolean(multiline)
    }

    const input = createElement(multiline ? 'textarea' : 'input', inputProps)

    return (
      <div className={classNames(styles.TextField, error && styles.error)}>
        <div className={styles.InnerWrapper}>
          {input}
          <label
            aria-hidden
            id={labelId}
            htmlFor={inputId}
            className={classNames(
              styles.Label,
              visuallyHideLabel ? styles.VisuallyHiddenLabel : ''
            )}
          >
            {label}
          </label>
        </div>

        {typeof error === 'string' && (
          <p id={errorId} aria-hidden className={styles.ErrorText}>
            {error}
          </p>
        )}
      </div>
    )
  }
)

TextField.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'email',
    'number',
    'password',
    'search',
    'tel',
    'url',
    'date',
    'datetime-local',
    'month',
    'time',
    'week',
    'currency',
  ]),
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autoSelect: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onFocus: PropTypes.func,
  visuallyHideLabel: PropTypes.bool,
}

export { TextField }
export default TextField
