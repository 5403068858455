/* eslint-disable react/no-danger */
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { camelCase } from 'lodash'
import { Heading } from '../../typography/Heading'
import { Caption } from '../../typography/Caption'
import { Container, Row, Col } from '../../grid'
import { RichText } from '../../rich-text'
import * as styles from './TableModule.module.scss'
import EnergyLoanRates from './energy-loan-rates'
import AutoLoanRates from './auto-and-harley-motorcycle-loan-rates'
import RecVehicleLoanRates from './motorcycle-and-recreational-vehicle-loan-rates'
import HolidayHours from './holiday-hours'
import MoneyMarketOptions from './money-market-options'
import CCPATable from './ccpa'
import DynamicTable from './DynamicTable'

const TableModule = ({
  backgroundColor,
  richHeadline,
  // TODO(mareksuscak): table field is scheduled for removal.
  table,
  caption,
  config,
  variables,
  lastUpdated,
  disclaimer,
  embedded,
}) => {
  let tableModule
  /* While tables are being hard coded, this switch statement determines which of the hard coded
  tables to display in the Contentful table module. The variable 'table' refers to table names that
  have been added into Contentful. Once a specific table has been selected within Contentful,
  the name is then put into the variable 'table' and the switch statement matches that name to
  the names and content we have hard coded.  */

  // TODO(mareksuscak): this switch statement is scheduled for removal - don't extend it.
  switch (table) {
    case 'holiday-hours':
      tableModule = <HolidayHours />
      break
    case 'auto-and-harley-motorcycle-loan-rates':
      tableModule = <AutoLoanRates />
      break
    case 'motorcycle-and-recreational-vehicle-loan-rates':
      tableModule = <RecVehicleLoanRates />
      break
    case 'money-market-options':
      tableModule = <MoneyMarketOptions />
      break
    case 'ccpa':
      tableModule = <CCPATable />
      break
    case 'energy-loan-rates':
      tableModule = <EnergyLoanRates />
      break
    default:
      tableModule = <DynamicTable caption={caption} config={config} variables={variables} />
      break
  }

  const sectionStyle = {}

  if (backgroundColor) {
    sectionStyle.backgroundColor = backgroundColor
  }

  if (embedded) {
    return (
      <div
        className={`${styles.sectionContainer} ${styles.sectionContainerEmbedded}`}
        style={sectionStyle}
        data-qa="table-module-container"
      >
        <div className={styles.contentContainer}>
          {richHeadline && (
            <RichText
              overrides={{
                paragraph: (node, children) =>
                  !isEmpty(children) && (
                    <Heading element="h2" size={3} id={`tableHeaderID-${camelCase(caption)}`}>
                      {children}
                    </Heading>
                  ),
              }}
            >
              {richHeadline}
            </RichText>
          )}

          {tableModule}

          {disclaimer && (
            <div className={styles.disclaimer}>
              <RichText>{disclaimer}</RichText>
            </div>
          )}

          {lastUpdated && <Caption>{lastUpdated}</Caption>}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.sectionContainer} style={sectionStyle} data-qa="table-module-container">
      <Container>
        <Row className={styles.centerContent}>
          <Col xs={12} md={8}>
            <div className={styles.contentContainer}>
              <RichText
                overrides={{
                  paragraph: (node, children) =>
                    !isEmpty(children) && (
                      <Heading element="h2" size={3} id={`tableHeaderID-${camelCase(caption)}`}>
                        {children}
                      </Heading>
                    ),
                }}
              >
                {richHeadline}
              </RichText>

              {tableModule}

              {disclaimer && (
                <div className={styles.disclaimer}>
                  <RichText>{disclaimer}</RichText>
                </div>
              )}

              {lastUpdated && <Caption>{lastUpdated}</Caption>}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

TableModule.propTypes = {
  richHeadline: PropTypes.object.isRequired,
  table: PropTypes.string.isRequired,
  caption: PropTypes.string,
  config: PropTypes.object,
  variables: PropTypes.arrayOf(PropTypes.object),
  lastUpdated: PropTypes.string,
  disclaimer: PropTypes.object,
  backgroundColor: PropTypes.string,
  embedded: PropTypes.bool,
}

export { TableModule }
export default TableModule
