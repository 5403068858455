import { useTranslation } from 'react-i18next'
import { getHolidays } from 'date-fns-holiday-us'
import { format, addDays, addYears } from 'date-fns'
import { enUS, es } from 'date-fns/locale'

const DayOfWeek = {
  Saturday: 6,
  Sunday: 0,
}

/**
 * This function will calculate a holiday closure date based on whether the holiday
 * date is a weekend or weekday.
 *
 * @param {*} date Holiday date
 * @returns Corresponding holiday closure date
 */
const getHolidayClosure = date => {
  const dayOfWeek = date.getDay()
  switch (dayOfWeek) {
    case DayOfWeek.Saturday:
      return addDays(date, -1)
    case DayOfWeek.Sunday:
      return addDays(date, 1)
    default:
      return date
  }
}

const locales = {
  en: enUS,
  es,
}

const currentYear = new Date().getFullYear()
const holidays = getHolidays(currentYear)

// NOTE:
// If you need to adjust the "Closed" date, you can do so by adding a `closedOn` override.
// You can pass in a date or a result of calling getHolidayClosure which applies
// the Saturday / Sunday offset correctly.

const holidayHours = [
  { date: holidays.martinLutherKingJrDay.date, key: 'martinLutherKingDay' },
  { date: holidays.presidentsDay.date, key: 'presidentsDay' },
  { date: holidays.memorialDay.date, key: 'memorialDay' },
  {
    date: holidays.juneteenth.date,
    key: 'juneteenth',
    closedOn: getHolidayClosure(holidays.juneteenth.date),
  },
  {
    date: holidays.independenceDay.date,
    key: 'independenceDay',
    closedOn: getHolidayClosure(holidays.independenceDay.date),
  },
  { date: holidays.laborDay.date, key: 'laborDay' },
  { date: holidays.columbusDay.date, key: 'columbusDay' },
  { date: holidays.veteransDay.date, key: 'veteransDay' },
  { date: holidays.thanksgiving.date, key: 'thanksgivingDay' },
  { date: addDays(holidays.christmas.date, -1), key: 'christmasEveDay' },
  { date: holidays.christmas.date, key: 'christmasDay' },
  { date: holidays.newYearsEve.date, key: 'newYearsEveDay' },
  {
    date: addYears(holidays.newYearsDay.date, +1),
    key: 'newYearsDay',
    closedOn: addYears(holidays.newYearsDay.date, +1),
  },
]

const HolidayHours = () => {
  const [t, i18n] = useTranslation()
  const [language] = i18n.language.split('-')
  const locale = locales[language] || locales.en

  return (
    <table>
      <caption>{t('HolidayHours.title', { defaultValue: 'Holiday Hours' })}</caption>
      <thead>
        <tr>
          <th scope="col">{t('HolidayHours.holidayColumnTitle', { defaultValue: 'Holiday' })}</th>
          <th scope="col">{t('HolidayHours.dateColumnTitle', { defaultValue: 'Date' })}</th>
          <th scope="col">{t('HolidayHours.closedColumnTitle', { defaultValue: 'Closed' })}</th>
        </tr>
      </thead>
      <tbody>
        {holidayHours.map(holidayHour => (
          <tr key={holidayHour.key}>
            <th scope="row">
              {t([`HolidayHours.${holidayHour.key}`, 'HolidayHours.unknown'], {
                defaultValue: 'N/A',
              })}
            </th>
            <td>{format(holidayHour.date, 'M/d/yyyy', { locale })}</td>
            <td>
              {holidayHour.closesAt &&
                `${t('HolidayHours.closesAt', {
                  defaultValue: 'Closed at {{time}}',
                  time: holidayHour.closesAt,
                })} `}
              {holidayHour.closedOn
                ? format(holidayHour.closedOn, 'EEEE, M/d/yyyy', { locale })
                : format(holidayHour.date, 'EEEE, M/d/yyyy', { locale })}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default HolidayHours
