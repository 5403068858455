import { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import useAutofocus from '../../../../utilities/useAutofocus'
import * as styles from './Input.module.scss'

const Input = ({ getInputProps }) => {
  const [t] = useTranslation()
  const inputRef = useRef(null)

  const { onClear, ...inputProps } = getInputProps({
    ref: inputRef,
    placeholder: '',
  })

  useAutofocus(inputRef, inputProps.autoFocus)

  return (
    <Fragment>
      <FontAwesomeIcon
        aria-hidden
        aria-label={t('SearchInput.searchIconAriaLabel', { defaultValue: 'Search icon' })}
        icon={faSearch}
        className={styles.SearchIcon}
      />
      <input
        aria-label="Search Input, autocomplete"
        placeholder="Search"
        type="search"
        {...inputProps}
        className={styles.InputModule}
      />
    </Fragment>
  )
}

Input.propTypes = {
  getInputProps: PropTypes.func.isRequired,
}

export { Input }
export default Input
