import PropTypes from 'prop-types'

const MoneyMarketOptions = ({ theme }) => {
  return (
    <table className={theme}>
      <thead>
        <tr>
          <th scope="col">Account</th>
          <th scope="col">Opening Deposit & Minimum Balance</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Select Money Market</td>
          <td>$25,000 or more</td>
        </tr>
        <tr>
          <td>Premier Money Market</td>
          <td>$50,000 or more</td>
        </tr>
        <tr>
          <td>Jumbo Money Market</td>
          <td>$100,000 or more</td>
        </tr>
      </tbody>
    </table>
  )
}

MoneyMarketOptions.propTypes = {
  theme: PropTypes.string,
}

export default MoneyMarketOptions
