import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './Row.module.scss'

const Row = forwardRef(({ element: Element = 'div', className, ...props }, ref) => {
  const classes = classNames(styles.Row, className)
  return <Element ref={ref} className={classes} data-qa="grid-row" {...props} />
})

Row.propTypes = {
  element: PropTypes.elementType,
  className: PropTypes.string,
}

export { Row }
export default Row
