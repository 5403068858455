import PropTypes from 'prop-types'
import { Fragment } from 'react'
import classNames from 'classnames'
import qs from 'qs'
import {
  faApple,
  faAndroid,
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import { Trans, useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '../button'
import { Heading } from '../typography/Heading'
import { Eyebrow } from '../typography/Eyebrow'
import { Caption } from '../typography/Caption'
import { UnstyledLink } from '../typography/UnstyledLink'
import { Link } from '../typography/Link'
import { List } from '../list'
import { Stack } from '../stack'
import { Container, Row, Col } from '../grid'

import { CoopLogo } from './components/CoopLogo'
import { EhlLogo } from './components/EhlLogo'
import { NcuaLogo } from './components/NcuaLogo'
import { intersperse } from '../../utilities/intersperse'

import * as styles from './Footer.module.scss'

const professionalIconsClasses = classNames(styles.FooterCopyrightItem, styles.professionalIcons)

const Footer = ({ menu }) => {
  const [t] = useTranslation()
  const copyrightYear = new Date().getFullYear()
  /**
   * the code below check the users current url, if the user is on a business page it
   * then sets isBusiness to "business" which is appended to the url for the login
   * page the login page checks if the url contians "business" if it does it sets the
   * login to the business default login.
   */
  let isBusiness = ''
  if (typeof window !== 'undefined') {
    const siteSelection = window.location.href
    if (siteSelection.includes('business')) {
      isBusiness = true
    } else {
      isBusiness = false
    }
  }

  const addedElement = key => {
    return (
      <span key={key} aria-hidden role="presentation" className={styles.Separator}>
        |
      </span>
    )
  }

  return (
    <footer className={styles.FooterContainer} role="contentinfo">
      <div className={styles.FooterHelp}>
        <Container data-qa="footer-container">
          <Row>
            <Col xs={12} data-qa="footer-help-container">
              <Heading
                element="h2"
                size={3}
                className={styles.FooterHelpTitle}
                data-qa="footer-help-title"
              >
                {t('Footer.needHelp', { defaultValue: 'Need Help?' })}
              </Heading>

              <Stack distribution="center" className={styles.FooterHelpInfo}>
                <Link to="/branch-locations" light>
                  {t('Footer.locationsLinkLabel', { defaultValue: 'Locations' })}
                </Link>
                <Link to="/contact-us" light>
                  {t('Footer.contactUsLinkLabel', { defaultValue: 'Contact Us' })}
                </Link>
                <span>
                  <Trans i18nKey="Footer.callUs">
                    Call:{' '}
                    <Link to="tel:+18004297626" alt="call (800) 429-7626" light>
                      (800) 429-7626
                    </Link>
                  </Trans>
                </span>
              </Stack>
              <Stack distribution="center" className={styles.FooterHelpInfo}>
                <span>{t('Footer.routingNumber', { defaultValue: 'Routing #: 307074580' })}</span>
                <span>{t('Footer.nmlsNumber', { defaultValue: 'NMLS #: 717246' })}</span>
              </Stack>

              <Button
                url={`/login${qs.stringify(isBusiness ? { action: 'business' } : {}, {
                  addQueryPrefix: true,
                })}`}
                alt={
                  isBusiness
                    ? t('Footer.businessLoginButtonAriaLabel', { defaultValue: 'Business Log In' })
                    : undefined
                }
                variant="light"
                data-qa="footer-help-button"
              >
                {t('Footer.loginButtonLabel', { defaultValue: 'Log In' })}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row className={styles.FooterNav} data-qa="footer-main-nav-container">
          <Col xs={6} md={3}>
            <Eyebrow element="h2" id="footerLinksPersonal">
              {t('Footer.personalMenuLabel', { defaultValue: 'Personal' })}
            </Eyebrow>
            <List className={styles.FooterNavList} unstyled aria-label="Footer Links Personal">
              {menu.footer_personal.map(menuItem => (
                <List.Item
                  key={menuItem.title}
                  className={styles.FooterNavItem}
                  aria-labelledby="footerLinksPersonal"
                >
                  <UnstyledLink
                    to={menuItem.urlPath}
                    className={styles.FooterNavLink}
                    data-qa="footer-main-nav-link"
                    alt={menuItem.ariaLabel}
                  >
                    {menuItem.title}
                  </UnstyledLink>
                </List.Item>
              ))}
            </List>
          </Col>

          <Col xs={6} md={3}>
            <Eyebrow element="h2" id="footerLinksBusiness">
              {t('Footer.businessMenuLabel', { defaultValue: 'Business' })}
            </Eyebrow>
            <List className={styles.FooterNavList} unstyled aria-label="Footer Links Business">
              {menu.footer_business.map(menuItem => (
                <List.Item
                  key={menuItem.title}
                  className={styles.FooterNavItem}
                  aria-labelledby="footerLinksBusiness"
                >
                  <UnstyledLink
                    to={menuItem.urlPath}
                    className={styles.FooterNavLink}
                    data-qa="footer-main-nav-link"
                    alt={menuItem.ariaLabel}
                  >
                    {menuItem.title}
                  </UnstyledLink>
                </List.Item>
              ))}
            </List>
          </Col>

          <Col xs={6} md={3}>
            <Eyebrow element="h2" id="footerLinksElevations">
              Elevations
            </Eyebrow>
            <List className={styles.FooterNavList} unstyled aria-label="Footer Links Elevations">
              {menu.footer_elevations.map(menuItem => (
                <List.Item
                  key={menuItem.title}
                  className={styles.FooterNavItem}
                  aria-labelledby="footerLinksElevations"
                >
                  <UnstyledLink
                    to={menuItem.urlPath}
                    className={styles.FooterNavLink}
                    data-qa="footer-main-nav-link"
                    alt={menuItem.ariaLabel}
                  >
                    {menuItem.title}
                  </UnstyledLink>
                </List.Item>
              ))}
            </List>
          </Col>

          <Col xs={6} md={3} data-qa="footer-social-container">
            <Eyebrow element="h2">
              {t('Footer.mobileAppsMenuLabel', { defaultValue: 'Mobile Apps' })}
            </Eyebrow>
            <Stack className={styles.FooterNavList}>
              <UnstyledLink
                to="https://apps.apple.com/us/app/elevations-credit-union-mobile/id506168516"
                className={styles.FooterBigIconLink}
                target="_blank"
                data-qa="footer-main-nav-app-link"
                alt={t('Footer.mobileAppsMenuLabelLinkAriaLabel', {
                  defaultValue: 'Mobile apps for iOS',
                })}
              >
                <FontAwesomeIcon icon={faApple} />
              </UnstyledLink>

              <UnstyledLink
                to="https://play.google.com/store/apps/details?id=com.elevationscu.elevationscu"
                className={styles.FooterBigIconLink}
                target="_blank"
                data-qa="footer-main-nav-app-link"
                alt={t('Footer.mobileAppsForAndroidLinkAriaLabel', {
                  defaultValue: 'Mobile apps for Android',
                })}
              >
                <FontAwesomeIcon icon={faAndroid} />
              </UnstyledLink>
            </Stack>

            <Eyebrow element="h2">{t('Footer.followUs', { defaultValue: 'Follow Us' })}</Eyebrow>
            <Stack className={styles.FooterNavList}>
              <Stack.Item className={styles.FooterSocialNavItem}>
                <UnstyledLink
                  to="https://www.facebook.com/ElevationsCU"
                  className={styles.FooterSocialLink}
                  target="_blank"
                  data-qa="footer-main-nav-social-link"
                  alt={t('Footer.facebookAccountLinkAriaLabel', {
                    defaultValue: 'Facebook account',
                  })}
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </UnstyledLink>
              </Stack.Item>

              <Stack.Item className={styles.FooterSocialNavItem}>
                <UnstyledLink
                  to="https://twitter.com/ElevationsCU"
                  className={styles.FooterSocialLink}
                  target="_blank"
                  data-qa="footer-main-nav-social-link"
                  alt={t('Footer.twitterAccountLinkAriaLabel', { defaultValue: 'Twitter account' })}
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </UnstyledLink>
              </Stack.Item>

              <Stack.Item className={styles.FooterSocialNavItem}>
                <UnstyledLink
                  to="https://www.instagram.com/elevationscu/"
                  className={styles.FooterSocialLink}
                  target="_blank"
                  data-qa="footer-main-nav-social-link"
                  alt={t('Footer.instagramAccountLinkAriaLabel', {
                    defaultValue: 'Instagram account',
                  })}
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </UnstyledLink>
              </Stack.Item>

              <Stack.Item className={styles.FooterSocialNavItem}>
                <UnstyledLink
                  to="https://www.linkedin.com/company/elevations-credit-union/"
                  className={styles.FooterSocialLink}
                  target="_blank"
                  data-qa="footer-main-nav-social-link"
                  alt={t('Footer.linkedInAccountLinkAriaLabel', {
                    defaultValue: 'LinkedIn account',
                  })}
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </UnstyledLink>
              </Stack.Item>

              <Stack.Item className={styles.FooterSocialNavItem}>
                <UnstyledLink
                  to="https://www.youtube.com/user/ElevationsCU"
                  className={styles.FooterSocialLink}
                  target="_blank"
                  data-qa="footer-main-nav-social-link"
                  alt={t('Footer.youtubeAccountLinkAriaLabel', { defaultValue: 'YouTube account' })}
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </UnstyledLink>
              </Stack.Item>
            </Stack>
            {process.env.SHOWSCREENSHARE && (
              <Fragment>
                <Eyebrow element="h2">{t('Footer.support', { defaultValue: 'Support' })}</Eyebrow>
                <Stack>
                  <Stack.Item>
                    <Button
                      data-qa="authorize-screenshare-btn"
                      id="authorize-screenshare-btn"
                      onClick={() =>
                        document.body.appendChild(document.createElement('sm-visitor-code'))
                      }
                    >
                      Authorize screenshare
                    </Button>
                  </Stack.Item>
                </Stack>
              </Fragment>
            )}
          </Col>
        </Row>

        <Row data-qa="footer-util-nav-container">
          <Col>
            <div className={styles.FooterNavUtilityLinks}>
              {intersperse(
                menu.footer_legal.map(menuItem => (
                  <UnstyledLink
                    key={menuItem.title}
                    to={menuItem.urlPath}
                    className={styles.FooterNavUtilityLink}
                    alt={menuItem.ariaLabel}
                    data-qa="footer-util-nav-link"
                  >
                    {menuItem.title}
                  </UnstyledLink>
                )),
                // eslint-disable-next-line react/no-array-index-key
                /* eslint no-dupe-keys: "error" */
                <span key="seperator" aria-hidden role="presentation" className={styles.Separator}>
                  |
                </span>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Caption className={styles.FooterCopyrightItem} data-qa="footer-copyright-left">
              {t('Footer.copyright', {
                defaultValue:
                  '©  {{copyrightYear}} Elevations Credit Union. All Rights Reserved. Routing # 307074580. This site is best viewed with a standards compliant browser.',
                copyrightYear,
              })}
              <br aria-hidden="true" />
              {t('Footer.equalHousingOpportunity', {
                defaultValue: 'Elevations Credit Union is an Equal Housing Opportunity lender.',
              })}
            </Caption>
          </Col>

          <Col md={4}>
            <Stack className={professionalIconsClasses}>
              <Stack.Item className={styles.FooterBadge}>
                <UnstyledLink
                  to="https://co-opcreditunions.org/locator/?ref=allco-op.org&sc=1"
                  title="CO-OP Credit Unions"
                  alt="CO-OP Credit Unions"
                  className={styles.FooterBadgeLink}
                >
                  <CoopLogo data-qa="footer-copyright-badge" className={styles.FooterBadgeImage} />
                </UnstyledLink>
              </Stack.Item>

              <Stack.Item className={styles.FooterBadge}>
                <EhlLogo data-qa="footer-copyright-badge" className={styles.FooterBadgeImage} />
              </Stack.Item>

              <Stack.Item className={styles.FooterBadge}>
                <UnstyledLink
                  to="https://www.ncua.gov"
                  alt="ncua.gov"
                  className={styles.FooterBadgeLink}
                >
                  <NcuaLogo data-qa="footer-copyright-badge" className={styles.FooterBadgeImage} />
                </UnstyledLink>
              </Stack.Item>
            </Stack>
          </Col>

          <Col md={4}>
            <Caption className={styles.FooterCopyrightItem} data-qa="footer-copyright-right">
              {t('Footer.federalInsurance', {
                defaultValue:
                  'Your savings are federally insured to at least $250,000 and backed by the full faith and credit of the United States Government. National Credit Union Administration, a U.S. Government Agency',
              })}
            </Caption>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  menu: PropTypes.object.isRequired,
}

export { Footer }
export default Footer
