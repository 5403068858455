import PropTypes from 'prop-types'
import * as styles from './ScreenReaderText.module.scss'

const ScreenReaderText = ({ children, ...props }) => {
  return (
    <span className={styles.ScreenReaderText} {...props}>
      {children}
    </span>
  )
}

ScreenReaderText.propTypes = {
  children: PropTypes.node,
}

export { ScreenReaderText }
export default ScreenReaderText
