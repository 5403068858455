import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import * as styles from './Heading.module.scss'

// with Server Side Rendering, Element within proptypes

const Heading = React.forwardRef(
  ({ children, className, element: Element = 'h2', size, forwardedRef, ...rest }, ref) => {
    const classes = classNames(
      className,
      size === 1 && styles.h1,
      size === 2 && styles.h2,
      size === 3 && styles.h3,
      size === 4 && styles.h4
    )

    return (
      <Element className={classes} data-qa="heading" ref={forwardedRef} {...rest}>
        {children}
      </Element>
    )
  }
)

Heading.propTypes = {
  element: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']),
  children: PropTypes.node,
  size: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  className: PropTypes.string,
  // reference: https://stackoverflow.com/questions/48007326/what-is-the-correct-proptype-for-a-ref-in-react
  forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
}

export { Heading }
export default Heading
