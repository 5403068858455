import { useState, useEffect } from 'react'
import axios from 'axios'
import get from 'lodash/get'
import find from 'lodash/find'

function usePageAlert() {
  const [upcomingEvent, setUpcomingEvent] = useState(null)
  const [status, setUpcomingEventStatus] = useState('loading')

  useEffect(() => {
    const fetchData = async () => {
      try {
        setUpcomingEventStatus('loading')

        const response = await axios({
          method: 'get',
          url: process.env.PAGE_ALERTS_API_URL,
        })

        // Filters events that match all tags
        const homePage = response.data.items[0]
        const pageAlertId = get(homePage, 'fields.pageAlert.en-US.sys.id', null)

        if (pageAlertId) {
          const includes = get(response.data, 'includes.Entry', [])
          const pageAlert = find(includes, entry => entry.sys.id === pageAlertId)
          setUpcomingEvent(pageAlert || null)
        }

        setUpcomingEventStatus('loaded')
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('Upcoming event could not be loaded')
        setUpcomingEventStatus('error')
      }
    }
    fetchData()
  }, [])

  return [upcomingEvent, status]
}

export { usePageAlert }
