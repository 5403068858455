import PropTypes from 'prop-types'

const AutoLoanRates = ({ theme }) => {
  return (
    <table className={theme}>
      <caption>Auto and Harley Motorcycle Loan Rates</caption>
      <thead>
        <tr>
          <th scope="col">Terms</th>
          <th scope="col">Model Year</th>
          <th scope="col">
            APR Starting At<sup>*</sup>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">Up to 63 Months</th>
          <td>2018-2020</td>
          <td>
            4.49%<sup>†</sup>
          </td>
        </tr>
        <tr>
          <th scope="row">Up to 63 Months</th>
          <td>2017-older</td>
          <td>
            4.99%<sup>†</sup>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

AutoLoanRates.propTypes = {
  theme: PropTypes.string,
}

export default AutoLoanRates
