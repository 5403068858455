import { Fragment, useEffect, useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import qs from 'qs'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGlobe,
  faMapSigns,
  faSignIn,
  faCommentAlt,
  faQuestionCircle,
  faClipboardList,
  faClipboard,
} from '@fortawesome/pro-regular-svg-icons'
import { faCalculatorAlt } from '@fortawesome/pro-light-svg-icons'
import { Modal } from '../../modal'
import { Link } from '../../typography/Link'
import { NavLink } from './components/NavLink'
import { SearchBar } from '../SearchBar'

import * as styles from './HamburgerMenu.module.scss'

const HamburgerMenu = ({ open, close, menu, location, pageContext }) => {
  const [t, i18n] = useTranslation()
  const [openSubmenuId, setOpenSubmenuId] = useState(null)
  const [searchIsActive, setSearchIsActive] = useState(false)
  const tabIndex = openSubmenuId !== null ? '-1' : '0'
  const navRootRef = useRef(null)
  const prevExpandedButtonRef = useRef(null)

  useEffect(() => {
    if (!navRootRef.current) {
      return
    }

    const navRootEl = navRootRef.current
    const expandedButton = navRootEl.querySelector('button[aria-expanded="true"]')
    const prevExpandedButton = prevExpandedButtonRef.current

    if (openSubmenuId && expandedButton) {
      const secondLevelButtons = expandedButton.nextElementSibling.querySelectorAll('li > button')
      if (secondLevelButtons.length > 0) {
        setTimeout(() => {
          secondLevelButtons[0].focus()
        }, 50)
      }
      prevExpandedButtonRef.current = expandedButton
    } else if (!openSubmenuId && prevExpandedButton) {
      prevExpandedButton.focus()
    }
  }, [openSubmenuId])

  const handleDismissModal = useCallback(() => {
    setOpenSubmenuId(null)
    setSearchIsActive(false)
    close()
  }, [])

  const handleSearchChange = useCallback(state => {
    setSearchIsActive(typeof state.searchTerm === 'string' && state.searchTerm.length > 0)
  }, [])

  const isBusiness = location.pathname.includes('business')

  const menuAriaName =
    open === 'search'
      ? t('HamburgerMenu.searchAriaLabel', { defaultValue: 'Search Menu' })
      : t('HamburgerMenu.ariaLabel', { defaultValue: 'Navigation Menu' })

  return (
    <Modal
      className={styles.HamburgerMenu}
      open={!!open}
      onDismiss={handleDismissModal}
      name={menuAriaName}
      theme="light"
    >
      <div
        className={classNames(
          styles.HamburgerMenuInner,
          openSubmenuId !== null && styles.HamburgerMenuInnerOpen
        )}
        data-qa="hamburger-menu-container"
      >
        {open === 'search' ? (
          <SearchBar onClickLink={close} autoFocus />
        ) : (
          <Fragment>
            <div className={styles.ShowMobile}>
              <SearchBar
                autoFocus={false}
                onChange={handleSearchChange}
                showResults={searchIsActive}
                onClickLink={close}
              />
            </div>

            {!searchIsActive && (
              <Fragment>
                <nav
                  role="navigation"
                  aria-label={t('HamburgerMenu.primaryMenuAriaLabel', {
                    defaultValue: 'Primary Navigation',
                  })}
                >
                  <ul data-qa="hamburger-menu-nav" ref={navRootRef}>
                    {menu.main.map(menuItem => (
                      <NavLink
                        key={menuItem.title}
                        label={menuItem.title}
                        to={menuItem.urlPath}
                        isTopLevel
                        openSubmenuId={openSubmenuId}
                        setSubnavOpen={setOpenSubmenuId}
                        subNavId={menuItem.title}
                        closeMenu={handleDismissModal}
                      >
                        {menuItem.children.length > 0 &&
                          menuItem.children.map(child => (
                            <NavLink
                              key={child.title}
                              label={child.title}
                              to={child.urlPath}
                              openSubmenuId={openSubmenuId}
                              setSubnavOpen={setOpenSubmenuId}
                              subNavId={menuItem.title}
                              closeMenu={handleDismissModal}
                            />
                          ))}
                      </NavLink>
                    ))}
                  </ul>
                </nav>

                {openSubmenuId ? null : (
                  <ul className={styles.UtilityNav} data-qa="hamburger-menu-util-nav">
                    <li>
                      <FontAwesomeIcon
                        aria-hidden
                        aria-label={t('HamburgerMenu.locationIconAriaLabel', {
                          defaultValue: 'Location icon',
                        })}
                        icon={faMapSigns}
                        className={styles.LinkIcon}
                      />
                      <Link to="/branch-locations" tabIndex={tabIndex} onClick={handleDismissModal}>
                        {t('HamburgerMenu.locationsLinkLabel', { defaultValue: 'Locations' })}
                      </Link>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        aria-hidden
                        aria-label={t('HamburgerMenu.careerIconAriaLabel', {
                          defaultValue: 'Career icon',
                        })}
                        icon={faClipboardList}
                        className={styles.LinkIcon}
                      />
                      <Link to="/careers" tabIndex={tabIndex} onClick={handleDismissModal}>
                        {t('HamburgerMenu.careerLinkLabel', { defaultValue: 'Careers' })}
                      </Link>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        aria-hidden
                        aria-label={t('HamburgerMenu.calculatorIconAriaLabel', {
                          defaultValue: 'Calculator icon',
                        })}
                        icon={faCalculatorAlt}
                        className={styles.LinkIcon}
                      />
                      <Link to="/calculators" tabIndex={tabIndex} onClick={handleDismissModal}>
                        {t('HamburgerMenu.calculatorsLinkLabel', { defaultValue: 'Calculators' })}
                      </Link>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        aria-hidden
                        aria-label={t('HamburgerMenu.loginIconAriaLabel', {
                          defaultValue: 'Login icon',
                        })}
                        icon={faSignIn}
                        className={styles.LinkIcon}
                      />
                      <Link
                        to={`/login${qs.stringify(isBusiness ? { action: 'business' } : {}, {
                          addQueryPrefix: true,
                        })}`}
                        tabIndex={tabIndex}
                        onClick={handleDismissModal}
                        alt={
                          isBusiness
                            ? t('HamburgerMenu.businessLoginButtonAriaLabel', {
                                defaultValue: 'Business Log In',
                              })
                            : undefined
                        }
                      >
                        {t('HamburgerMenu.loginButtonLabel', { defaultValue: 'Log In' })}
                      </Link>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        aria-hidden
                        aria-label={t('HamburgerMenu.contactUsIconAriaLabel', {
                          defaultValue: 'Contact Us icon',
                        })}
                        icon={faCommentAlt}
                        className={styles.LinkIcon}
                      />
                      <Link to="/contact-us" tabIndex={tabIndex} onClick={handleDismissModal}>
                        {t('HamburgerMenu.contactUsLinkLabel', { defaultValue: 'Contact Us' })}
                      </Link>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        aria-hidden
                        aria-label={t('HamburgerMenu.globeIconAriaLabel', {
                          defaultValue: 'Globe icon',
                        })}
                        icon={faGlobe}
                        className={styles.LinkIcon}
                      />
                      {i18n.language === 'es-US' ? (
                        <Link
                          to={pageContext.pathname}
                          tabIndex={tabIndex}
                          alt={t('HamburgerMenu.englishButtonLongLabel', {
                            defaultValue: 'English',
                          })}
                          onMouseDown={() => {
                            window.localStorage.setItem('preferredLanguage', 'en-US')
                          }}
                          onClick={handleDismissModal}
                          locale="en-US"
                          lang="en-US"
                        >
                          {t('HamburgerMenu.englishButtonLongLabel', { defaultValue: 'English' })}
                        </Link>
                      ) : (
                        <Link
                          to={pageContext.pathname}
                          tabIndex={tabIndex}
                          alt={t('Header.spanishButtonLongLabel', { defaultValue: 'Español' })}
                          onMouseDown={() => {
                            window.localStorage.setItem('preferredLanguage', 'es-US')
                          }}
                          onClick={handleDismissModal}
                          locale="es-US"
                          lang="es-US"
                        >
                          {t('HamburgerMenu.spanishButtonLongLabel', { defaultValue: 'Español' })}
                        </Link>
                      )}
                    </li>
                    <li>
                      <FontAwesomeIcon
                        aria-hidden
                        aria-label={t('HamburgerMenu.faqIconAriaLabel', {
                          defaultValue: 'Question mark icon',
                        })}
                        icon={faQuestionCircle}
                        className={styles.LinkIcon}
                      />
                      <Link to="/resources" tabIndex={tabIndex} onClick={handleDismissModal}>
                        {t('HamburgerMenu.faqLinkLabel', {
                          defaultValue: 'Resources & FAQs',
                        })}
                      </Link>
                    </li>
                  </ul>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </Modal>
  )
}

HamburgerMenu.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  menu: PropTypes.object.isRequired,
}

export { HamburgerMenu }
export default HamburgerMenu
