export const MIN_CHARACTERS = 3

/**
 * Sets up functional boosts for the provided query which increases result scores
 * based on the site priority and popularity.
 */
export const boostQuery = queryConfig => {
  return {
    ...queryConfig,
    functional_boosts: {
      page: {
        'site-priority': 'linear',
        popularity: 'linear',
      },
    },
  }
}

export const saveRecentSearches = newRecentSearches => {
  try {
    window.localStorage.setItem('recent-searches', JSON.stringify(newRecentSearches))
  } catch (error) {
    // This is a no-op. Handles the limitation in some browsers where private mode disables
    // all kinds of client-side storage.
  }
}

export const getRecentSearches = () => {
  const recentSearchesJson = window.localStorage.getItem('recent-searches') || '[]'
  return JSON.parse(recentSearchesJson)
}

export const isValidSearchTerm = searchTerm =>
  typeof searchTerm === 'string' && searchTerm.length >= MIN_CHARACTERS
