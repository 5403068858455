import { Fragment, useCallback } from 'react'
import PropTypes from 'prop-types'
import difference from 'lodash/difference'
import last from 'lodash/last'
import { withSearch } from '@elastic/react-search-ui'
import { Trans, useTranslation } from 'react-i18next'
import { ScreenReaderText } from '../../../screen-reader-text'

import { List } from '../../../list'
import { Heading } from '../../../typography/Heading'
import { SearchResult } from './SearchResult'

import * as styles from './SearchResults.module.scss'

const RESULTS_PER_PAGE = 7

function getRaw(result, value) {
  console.log(result)
  if (!result[value] || !result[value].raw) return
  return result[value].raw
}

const SearchResults = ({
  wasSearched,
  error,
  isLoading,
  recentSearches,
  topSearches,
  results,
  totalResults,
  resultsPerPage,
  setResultsPerPage,
  setSearchTerm,
  trackClickThrough,
  onClickLink,
}) => {
  const [t] = useTranslation()
  const hasRecentSearches = recentSearches.length > 0
  const uniqueSuggestedSearches = difference(topSearches, recentSearches)
  const hasUniqueSuggestedSearches = uniqueSuggestedSearches.length > 0

  // This focus trap is used to focus an invisible anchor upon clicking the View more results
  // button which would normally focus the next element (modal close button). This improves
  // accessibility of this infinite list so that the next focusable element is always the first
  // item on the next page of results.
  const trapFocus = useCallback(() => {
    const anchors = document.querySelectorAll(`.${styles.InfiniteListAnchor}`)
    const lastAnchor = last(anchors)
    if (lastAnchor) {
      lastAnchor.focus()
    }
  }, [])

  // Site Search won't return more than 100 results per page and our infinite list
  // implementation always fetches a single page of results to avoid having to fire
  // multiple requests when deep-linking into 2nd and subsequent page of search results.
  const cappedTotalResults = Math.min(totalResults, 100)

  return (
    <Fragment>
      {wasSearched || isLoading ? (
        <Fragment>
          <Heading
            element="h2"
            size={1}
            className={styles.AutocompleteHeading}
            aria-label={t('SearchResults.resultsSectionTitle', { defaultValue: 'Results' })}
          >
            {t('SearchResults.resultsSectionTitle', { defaultValue: 'Results' })}
          </Heading>

          <div className={styles.SearchResults} role="alert" aria-live="polite">
            {results.length > 0 && (
              <List unstyled>
                {results.map((result, index) => (
                  <Fragment key={`result-${getRaw(result, 'id')}`}>
                    <SearchResult
                      result={result}
                      aria-label={getRaw(result, 'title')}
                      onClickLink={() => {
                        trackClickThrough(result.id.raw)
                        if (onClickLink) {
                          onClickLink()
                        }
                      }}
                    />

                    {(index + 1) % RESULTS_PER_PAGE === 0 && (
                      <List.Item className={styles.InfiniteListAnchor} tabIndex="-1" />
                    )}
                  </Fragment>
                ))}
              </List>
            )}

            {results.length === 0 && !isLoading && (
              <p
                style={{ textAlign: 'center' }}
                aria-label={t('SearchResults.noResultsAriaMessage')}
              >
                <Trans i18nKey="SearchResults.noResultsAriaMessage">
                  Sorry, we couldn't find any results for you.
                  <br aria-hidden="true" />
                  Please check your spelling or use a different search term.
                </Trans>
              </p>
            )}

            {results.length === 0 && isLoading && !error && (
              <p aria-hidden="true" style={{ textAlign: 'center' }}>
                {t('SearchResults.searchInProgress', {
                  defaultValue: "We're searching our database…",
                })}
              </p>
            )}

            {results.length === 0 && error && (
              <p aria-hidden="true" style={{ textAlign: 'center' }}>
                {error}
              </p>
            )}

            {cappedTotalResults > results.length && (
              <button
                key={`view-more-${resultsPerPage + RESULTS_PER_PAGE}-${cappedTotalResults}`}
                className={styles.ViewMoreButton}
                type="button"
                onClick={() => {
                  trapFocus()
                  setResultsPerPage(resultsPerPage + RESULTS_PER_PAGE)
                }}
              >
                {t('SearchResults.viewMoreResultsButtonLabel', {
                  defaultValue: 'View more results',
                })}
              </button>
            )}

            {cappedTotalResults === results.length && cappedTotalResults !== 0 && (
              <p className={styles.EndOfResults}>
                {t('SearchResults.endOfResults', { defaultValue: 'End of results' })}
              </p>
            )}
          </div>
        </Fragment>
      ) : (
        <Fragment>
          {hasRecentSearches && (
            <Fragment>
              <Heading
                element="h2"
                size={1}
                className={styles.AutocompleteHeading}
                id="recentSearches"
                aria-label="Recent Searches"
              >
                {t('SearchResults.recentSearchesSectionTitle', { defaultValue: 'Recent Searches' })}
              </Heading>

              <div className={styles.SearchResults}>
                <List unstyled aria-label="Recent Searches">
                  {recentSearches.map(query => (
                    <List.Item
                      key={query}
                      className={styles.ListItem}
                      aria-labelledby="recentSearches"
                    >
                      <button
                        className={styles.Suggestion}
                        type="button"
                        onClick={() => setSearchTerm(query)}
                        aria-label={query}
                      >
                        {query}
                      </button>
                    </List.Item>
                  ))}
                </List>
              </div>
            </Fragment>
          )}

          {hasUniqueSuggestedSearches && (
            <Fragment>
              <Heading
                element="h2"
                size={1}
                className={styles.AutocompleteHeading}
                id="suggestedSearchResults"
              >
                {t('SearchResults.suggestedSearchesSectionTitle', {
                  defaultValue: 'Suggested Searches',
                })}
              </Heading>

              <div className={styles.SearchResults}>
                <List unstyled aria-label="Suggested Searches">
                  {uniqueSuggestedSearches.map(query => (
                    <List.Item
                      key={query}
                      className={styles.ListItem}
                      aria-labelledby="suggestedSearchResults"
                    >
                      <button
                        className={styles.Suggestion}
                        type="button"
                        onClick={() => setSearchTerm(query)}
                        aria-label={query}
                      >
                        {query}
                      </button>
                    </List.Item>
                  ))}
                </List>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

SearchResults.propTypes = {
  topSearches: PropTypes.arrayOf(PropTypes.string).isRequired,
  recentSearches: PropTypes.arrayOf(PropTypes.string).isRequired,
  results: PropTypes.array.isRequired,
  wasSearched: PropTypes.bool.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  totalResults: PropTypes.number.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  setResultsPerPage: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  trackClickThrough: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
}

const mapContextToProps = context => ({
  setSearchTerm: context.setSearchTerm,
  setResultsPerPage: context.setResultsPerPage,
  resultsPerPage: context.resultsPerPage,
  totalResults: context.totalResults,
  results: context.results,
  error: context.error,
  isLoading: context.isLoading,
  wasSearched: context.wasSearched && context.searchTerm.length > 0,
  trackClickThrough: context.trackClickThrough,
})

const ConnectedSearchResults = withSearch(mapContextToProps)(SearchResults)

export { ConnectedSearchResults as SearchResults }
export default ConnectedSearchResults
