import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './Stack.module.scss'

const Item = ({ className, children, fill }) => {
  const classes = classNames(styles.Item, fill && styles.ItemFill, className)

  return (
    <div className={classes} data-qa="stack-item">
      {children}
    </div>
  )
}

Item.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  fill: PropTypes.bool,
}

export { Item }
export default Item
