// This module does not use ES imports because it's being imported by
// a Node.js module and Node runtime does not support stable ES modules
// just yet.

const trim = require('lodash/trim')

const normalizePagePath = (path, { locale, defaultLocale }) => {
  const trimmedPath = trim(path, '/')

  if (locale === defaultLocale) {
    return `/${trimmedPath}`
  }

  if (trimmedPath === '') {
    return `/${locale}`
  }

  return `/${locale}/${trimmedPath}`
}

exports.default = normalizePagePath
module.exports = normalizePagePath
