import classNames from 'classnames'
import PropTypes from 'prop-types'
import * as styles from './Caption.module.scss'

const Caption = ({ children, className, ...props }) => {
  const classes = classNames(styles.Caption, className)

  return (
    <p className={classes} data-qa="caption" {...props}>
      {children}
    </p>
  )
}

Caption.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export { Caption }
export default Caption
