// extracted by mini-css-extract-plugin
export var FooterContainer = "Footer-module--FooterContainer--1kpHc";
export var FooterHelp = "Footer-module--FooterHelp--gh-1i";
export var FooterHelpTitle = "Footer-module--FooterHelpTitle--3WDGZ";
export var FooterHelpInfo = "Footer-module--FooterHelpInfo--1Bh3s";
export var FooterNav = "Footer-module--FooterNav--3AJSW";
export var FooterNavList = "Footer-module--FooterNavList--3Fxwq";
export var FooterNavItem = "Footer-module--FooterNavItem--3dtuB";
export var FooterNavLink = "Footer-module--FooterNavLink--3A02Z";
export var FooterNavUtilityLinks = "Footer-module--FooterNavUtilityLinks--3lQUp";
export var FooterNavUtilityLink = "Footer-module--FooterNavUtilityLink--3DbJi";
export var Separator = "Footer-module--Separator--2_4zL";
export var FooterBigIconLink = "Footer-module--FooterBigIconLink--M3KVE";
export var FooterSocialNavItem = "Footer-module--FooterSocialNavItem--yhql9";
export var FooterSocialLink = "Footer-module--FooterSocialLink--S3ggm";
export var FooterCopyrightItem = "Footer-module--FooterCopyrightItem--2mNPN";
export var FooterBadge = "Footer-module--FooterBadge--WccP2";
export var FooterBadgeLink = "Footer-module--FooterBadgeLink--1W73b";
export var FooterBadgeImage = "Footer-module--FooterBadgeImage--1dL7Q";
export var professionalIcons = "Footer-module--professionalIcons--3YSGU";