import PropTypes from 'prop-types'

import { SearchBox } from '@elastic/react-search-ui'
import * as styles from './SearchInput.module.scss'
import { ResetButton } from './ResetButton'
import { Input } from './Input'

const SearchInput = ({ handleInputClear, autoFocus }) => (
  <div className={styles.SearchBox}>
    <SearchBox
      searchAsYouType
      debounceLength={500}
      inputView={Input}
      aria-label="Search Input"
      inputProps={{ autoFocus, onClear: handleInputClear }}
    />
    <ResetButton />
  </div>
)

SearchInput.propTypes = {
  handleInputClear: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
}

export { SearchInput }
export default SearchInput
