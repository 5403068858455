import { useTranslation } from 'react-i18next'

const EhlLogo = props => {
  const [t] = useTranslation()
  return (
    <svg
      width={69}
      height={69}
      viewBox="0 0 1130.000000 1209.000000"
      role="img"
      alt=""
      aria-label={t('EhlLogo.ariaLabel', { defaultValue: 'Equal Housing Opportunity' })}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      {...props}
    >
      <g
        transform="translate(0.000000,1209.000000) scale(0.100000,-0.100000)"
        fill="#333"
        stroke="none"
      >
        <path
          d="M2805 10202 l-2800 -1879 -3 -761 -2 -762 370 0 370 0 0 -2239 0
      -2239 22 -5 c12 -4 2224 -9 4915 -13 l4893 -7 0 2246 0 2247 365 0 365 0 -1
      748 c0 411 -4 755 -7 765 -4 9 -1265 863 -2802 1897 -2686 1807 -2797 1880
      -2840 1880 -43 0 -154 -73 -2845 -1878z m4699 -1324 l1845 -1233 1 -1902 0
      -1903 -3690 0 -3690 0 2 1912 3 1911 1830 1229 c1007 675 1835 1226 1842 1224
      7 -3 842 -560 1857 -1238z"
        />
        <path
          d="M3700 7205 l0 -805 1950 0 1950 0 0 203 c0 112 -3 474 -7 805 l-6
        602 -1944 0 -1943 0 0 -805z"
        />
        <path
          d="M3710 5345 l0 -805 1950 0 1950 0 0 805 0 805 -1950 0 -1950 0 0
        -805z"
        />
        <path
          d="M1939 2070 c-79 -20 -154 -61 -197 -108 -62 -68 -87 -125 -105 -232
        -15 -84 -15 -105 -2 -182 27 -167 95 -255 244 -316 74 -30 218 -31 286 -2 60
        26 68 25 118 -8 43 -28 111 -62 125 -62 5 0 16 16 25 35 l17 35 -74 37 -75 37
        30 33 c89 103 125 299 79 444 -41 132 -115 217 -235 270 -51 23 -182 33 -236
        19z m209 -120 c107 -46 162 -151 162 -309 0 -108 -20 -187 -58 -234 -14 -17
        -27 -34 -29 -36 -1 -2 -20 8 -40 22 -40 28 -85 47 -109 47 -7 0 -21 -16 -29
        -36 -18 -44 -18 -44 -3 -44 7 0 32 -7 56 -17 l43 -16 -31 -14 c-47 -19 -114
        -16 -187 9 -128 44 -189 160 -181 342 3 82 8 102 37 159 41 82 72 111 141 137
        69 25 155 21 228 -10z"
        />
        <path
          d="M6400 2074 c-138 -23 -171 -36 -248 -101 -58 -49 -78 -83 -112 -187
        -20 -60 -22 -83 -18 -177 5 -99 9 -115 41 -181 43 -87 66 -114 132 -153 104
        -61 118 -65 223 -65 158 0 252 47 332 167 42 63 50 85 65 165 15 85 15 101 1
        186 -18 107 -60 197 -114 242 -86 71 -221 117 -302 104z m122 -114 c61 -18
        132 -90 159 -162 20 -53 24 -79 22 -170 -1 -93 -5 -114 -29 -168 -32 -72 -86
        -119 -166 -146 -71 -23 -172 -14 -227 20 -48 31 -115 113 -129 158 -30 98 -19
        253 24 338 62 125 195 175 346 130z"
        />
        <path
          d="M7997 2056 c-121 -36 -177 -103 -177 -212 0 -104 60 -171 185 -208
        33 -10 71 -21 85 -26 14 -4 51 -13 82 -20 31 -7 74 -22 95 -34 43 -24 83 -78
        83 -112 0 -42 -37 -89 -89 -114 -43 -20 -60 -22 -142 -18 -80 3 -100 8 -136
        31 -49 31 -70 58 -84 110 l-10 37 -55 0 c-48 0 -55 -2 -50 -17 3 -10 10 -36
        15 -58 38 -141 143 -205 337 -205 101 0 165 17 221 59 59 44 86 97 91 177 5
        69 -15 120 -65 165 -58 55 -87 65 -340 126 -49 12 -69 23 -94 50 -26 31 -30
        41 -24 72 14 85 128 137 248 112 33 -7 72 -22 86 -33 29 -22 60 -74 61 -100 0
        -15 9 -18 55 -18 54 0 55 1 55 28 0 49 -48 130 -97 164 -48 32 -165 68 -223
        67 -19 0 -70 -10 -113 -23z"
        />
        <path
          d="M10084 2070 c-211 -43 -337 -228 -321 -470 16 -244 171 -391 413
        -390 136 1 194 18 306 89 l58 36 0 158 0 157 -180 0 -180 0 0 -50 0 -50 125 0
        125 0 0 -83 0 -82 -61 -34 c-51 -28 -74 -34 -145 -39 -118 -7 -193 11 -248 59
        -89 78 -126 228 -92 372 20 83 60 144 123 186 62 41 114 54 193 49 105 -8 176
        -54 203 -133 8 -22 18 -40 23 -40 5 1 28 4 52 9 42 8 43 9 37 39 -18 88 -106
        173 -209 201 -68 19 -171 26 -222 16z"
        />
        <path
          d="M880 1645 l0 -425 315 0 315 0 0 50 0 50 -260 0 -260 0 0 145 0 145
        235 0 235 0 0 50 0 50 -235 0 -235 0 0 130 0 130 250 0 250 0 0 50 0 50 -305
        0 -305 0 0 -425z"
        />
        <path
          d="M2582 1758 c5 -297 6 -316 28 -373 27 -70 58 -107 120 -143 43 -26
        51 -27 185 -27 135 0 142 1 187 27 25 16 60 45 78 65 56 69 62 106 67 451 l5
        312 -60 0 -61 0 -3 -312 c-3 -297 -4 -315 -25 -360 -32 -69 -75 -88 -196 -88
        -55 0 -98 5 -104 11 -6 6 -22 17 -35 23 -14 7 -32 33 -45 66 -21 53 -22 69
        -23 358 l0 302 -61 0 -61 0 4 -312z"
        />
        <path
          d="M3636 2003 c-14 -38 -86 -229 -160 -426 l-134 -358 60 3 61 3 45 128
        45 127 177 0 178 0 45 -127 44 -128 66 -3 65 -3 -170 426 -170 425 -63 0 -63
        0 -26 -67z m123 -135 c16 -51 47 -139 69 -195 l40 -103 -145 0 c-79 0 -142 3
        -140 8 4 7 126 346 133 370 7 24 14 11 43 -80z"
        />
        <path
          d="M4210 1645 l0 -425 265 0 265 0 0 50 0 50 -210 0 -210 0 0 375 0 375
        -55 0 -55 0 0 -425z"
        />
        <path
          d="M5200 1645 l0 -425 60 0 60 0 0 200 0 200 215 0 215 0 0 -200 0 -200
        55 0 55 0 0 425 0 425 -55 0 -55 0 0 -175 0 -175 -215 0 -215 0 0 175 0 175
        -60 0 -60 0 0 -425z"
        />
        <path
          d="M6972 1758 c3 -264 7 -321 21 -366 21 -63 63 -113 126 -150 44 -26
        52 -27 186 -27 133 0 142 1 185 26 25 15 60 46 79 69 57 72 61 99 61 447 l0
        313 -54 0 -55 0 -3 -312 c-3 -297 -4 -315 -25 -360 -32 -68 -75 -88 -192 -88
        -109 0 -147 18 -189 89 l-27 46 -4 312 -3 313 -55 0 -54 0 3 -312z"
        />
        <path d="M8620 1645 l0 -425 60 0 60 0 0 425 0 425 -60 0 -60 0 0 -425z" />
        <path
          d="M8930 1645 l0 -425 55 0 55 0 2 329 3 330 215 -330 215 -329 63 0 62
        0 0 425 0 425 -55 0 -55 0 -2 -334 -3 -335 -219 335 -219 334 -58 0 -59 0 0
        -425z"
        />
        <path
          d="M1150 1025 c-14 -8 -33 -14 -43 -15 -10 0 -39 -11 -65 -24 -111 -58
        -200 -186 -226 -325 -21 -110 -20 -186 3 -269 36 -129 105 -237 189 -295 74
        -52 135 -67 266 -67 l120 0 83 41 c101 50 161 106 202 188 44 87 61 161 61
        271 0 218 -92 381 -265 468 -81 40 -273 57 -325 27z m259 -129 c149 -76 212
        -205 198 -404 -7 -90 -40 -197 -74 -235 -10 -10 -45 -40 -78 -65 -58 -44 -63
        -46 -142 -50 -123 -7 -159 2 -228 53 -71 54 -118 124 -135 204 -16 75 -8 248
        14 317 18 56 77 131 122 154 16 8 37 20 46 28 48 40 196 39 277 -2z"
        />
        <path
          d="M4007 1010 c-77 -27 -90 -35 -155 -95 -179 -161 -194 -531 -30 -737
        34 -44 140 -116 199 -136 47 -16 252 -16 298 1 86 30 166 82 213 142 56 69 84
        138 105 255 17 95 9 175 -26 291 -36 115 -114 205 -231 264 -103 52 -253 58
        -373 15z m302 -114 c151 -77 211 -201 197 -411 -8 -130 -48 -220 -123 -277
        -83 -63 -99 -68 -203 -67 -117 1 -125 4 -200 58 -51 37 -67 57 -98 120 l-37
        75 0 135 c0 126 2 141 28 202 31 74 68 114 141 154 72 40 93 45 172 41 51 -3
        86 -12 123 -30z"
        />
        <path
          d="M1910 535 l0 -495 65 0 65 0 0 200 0 200 165 0 c120 0 179 4 222 16
        32 9 70 18 85 21 35 7 93 70 124 137 19 40 24 68 24 130 0 69 -4 85 -30 131
        -30 53 -68 89 -125 117 -54 28 -158 38 -378 38 l-217 0 0 -495z m538 347 c53
        -28 81 -83 82 -157 0 -67 -26 -108 -89 -140 -48 -24 -58 -25 -226 -25 l-175 0
        0 176 0 176 183 -4 c166 -3 186 -5 225 -26z"
        />
        <path
          d="M2830 535 l0 -495 65 0 65 0 0 200 0 200 169 0 c92 0 181 4 197 9 16
        5 49 15 74 22 66 19 93 41 135 111 35 59 38 69 39 148 1 132 -42 208 -149 262
        -54 28 -158 38 -378 38 l-217 0 0 -495z m527 351 c57 -24 83 -70 83 -145 0
        -86 -13 -112 -73 -149 l-53 -32 -177 0 -177 0 0 176 0 176 178 -4 c141 -3 186
        -8 219 -22z"
        />
        <path
          d="M4820 536 l0 -496 65 0 65 0 0 220 0 220 105 0 c66 0 118 -5 138 -13
        52 -22 137 -124 233 -280 l89 -146 78 -1 c42 0 77 2 77 5 0 3 -41 70 -91 150
        -92 148 -170 244 -224 277 -29 17 -29 18 -6 18 36 0 90 20 146 55 127 80 143
        282 32 398 -67 70 -123 80 -444 85 l-263 3 0 -495z m511 374 c136 -38 180
        -166 90 -262 -47 -51 -78 -57 -298 -58 l-173 0 0 165 0 165 173 0 c94 0 188
        -5 208 -10z"
        />
        <path
          d="M5730 970 l0 -60 165 0 165 0 0 -435 0 -435 65 0 65 0 0 435 0 435
        160 0 160 0 0 60 0 60 -390 0 -390 0 0 -60z"
        />
        <path
          d="M6650 703 c0 -197 4 -352 11 -392 21 -118 82 -211 167 -252 40 -19
        67 -23 185 -27 125 -4 142 -2 195 18 123 49 172 109 196 238 13 68 16 157 16
        415 l0 327 -64 0 -65 0 -3 -367 -3 -368 -25 -45 c-16 -28 -43 -55 -74 -75 -47
        -28 -55 -30 -152 -30 -125 1 -158 16 -212 94 l-37 53 -3 369 -3 369 -64 0 -65
        0 0 -327z"
        />
        <path
          d="M7640 535 l0 -495 60 0 60 0 0 388 0 387 258 -387 257 -387 68 -1 67
        0 0 495 0 495 -60 0 -60 0 -2 -384 -3 -383 -259 383 -260 384 -63 0 -63 0 0
        -495z"
        />
        <path d="M8650 535 l0 -495 65 0 65 0 0 495 0 495 -65 0 -65 0 0 -495z" />
        <path
          d="M8940 970 l0 -60 160 0 160 0 0 -435 0 -435 65 0 65 0 0 435 0 435
        165 0 165 0 0 60 0 60 -390 0 -390 0 0 -60z"
        />
        <path
          d="M9766 1003 c9 -16 95 -145 191 -288 l173 -260 0 -207 0 -208 65 0 65
        0 0 208 0 207 180 263 c99 144 187 273 196 287 l16 25 -74 0 -75 0 -135 -207
        c-75 -115 -142 -221 -151 -237 l-15 -30 -27 55 c-15 29 -40 70 -55 89 -15 19
        -69 101 -120 183 l-92 147 -80 0 -80 0 18 -27z"
        />
      </g>
    </svg>
  )
}

EhlLogo.propTypes = {}

export { EhlLogo }
export default EhlLogo
