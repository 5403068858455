import { Children, isValidElement } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { wrapWithComponent } from '../../utilities/components'
import { Item } from './Item'
import { variationName } from './utils'
import * as styles from './Stack.module.scss'

const Stack = ({ children, vertical, wrap, alignment, distribution, className }) => {
  const classes = classNames(
    styles.List,
    vertical && styles.vertical,
    distribution && styles[variationName('distribution', distribution)],
    alignment && styles[variationName('alignment', alignment)],
    wrap === false && styles.noWrap,
    className
  )

  const items = Children.toArray(children)
    .filter(child => isValidElement(child))
    .map((child, index) => {
      const props = { key: index }
      return wrapWithComponent(child, Item, props)
    })

  return (
    <div className={classes} data-qa="stack">
      {items}
    </div>
  )
}

Stack.propTypes = {
  className: PropTypes.string,
  /** Elements to display inside list */
  children: PropTypes.node,
  /** Adjust vertical alignment of elements */
  alignment: PropTypes.oneOf(['leading', 'trailing', 'center', 'fill', 'baseline']),
  /** Adjust horizontal alignment of elements */
  distribution: PropTypes.oneOf([
    'equalSpacing',
    'leading',
    'trailing',
    'center',
    'fill',
    'fillEvenly',
  ]),
  /** Stack the elements vertically */
  vertical: PropTypes.bool,
  /** Wrap stack elements to additional rows as needed on small screens (Defaults to true) */
  wrap: PropTypes.bool,
}

Stack.Item = Item

export { Stack }
export default Stack
