// extracted by mini-css-extract-plugin
export var List = "Stack-module--List--366g1";
export var noWrap = "Stack-module--noWrap--3zFzC";
export var distributionLeading = "Stack-module--distributionLeading--3E8Dx";
export var distributionTrailing = "Stack-module--distributionTrailing--3RJQB";
export var distributionCenter = "Stack-module--distributionCenter--Uhbh8";
export var distributionEqualSpacing = "Stack-module--distributionEqualSpacing--r1MpJ";
export var distributionFill = "Stack-module--distributionFill--3ydff";
export var Item = "Stack-module--Item--14Fy-";
export var distributionFillEvenly = "Stack-module--distributionFillEvenly--2POKj";
export var alignmentLeading = "Stack-module--alignmentLeading--1of-K";
export var alignmentTrailing = "Stack-module--alignmentTrailing--3SdFl";
export var alignmentCenter = "Stack-module--alignmentCenter--2VXSP";
export var alignmentFill = "Stack-module--alignmentFill--1GwZ8";
export var alignmentBaseline = "Stack-module--alignmentBaseline--3tehZ";
export var vertical = "Stack-module--vertical--eAlgC";
export var ItemFill = "Stack-module--Item-fill--3b0GG";