import PropTypes from 'prop-types'
import compact from 'lodash/compact'
import { useTranslation } from 'react-i18next'
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Heading } from '../typography/Heading'
import { Link } from '../typography/Link'
import { List } from '../list'
import { ModalTableLink } from '../modal-table-link'
import { intersperse } from '../../utilities/intersperse'
import { Tooltip } from '../tooltip'

function inline(type, node) {
  return (
    <span key={node.data.target.sys.id}>
      type: {node.nodeType} id: {node.data.target.sys.id}
    </span>
  )
}

function getOptions({ listType, theme, overrides } = {}) {
  // const reservedT = document.getElementsByTagName('h2')
  // let textT
  // let newTextT
  // for (let i = 0; i < reservedT.length; i += 1) {
  //   textT = reservedT[i].innerHTML
  //   if (!textT.includes('</span>')) {
  //     newTextT = textT.replace(
  //       /™/gi,
  //       "<span style='font-size: .4em; vertical-align: super'>™</span>"
  //     )
  //     document.getElementsByTagName('h2')[i].innerHTML = newTextT
  //   }
  // }

  return {
    renderNode: {
      [BLOCKS.DOCUMENT]: (node, children) => children,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="rte-block" lang={node.data.lang}>
          {children}
        </p>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <Heading element="h1" size={1}>
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Heading element="h2" size={2}>
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Heading element="h3" size={3}>
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Heading element="h4" size={4}>
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Heading element="h4" size={4}>
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Heading element="h4" size={4}>
          {children}
        </Heading>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const [_t, i18n] = useTranslation()
        const { contentType } = node.data.target.sys

        const fields = node.data.target.fields || {}

        const tooltipText =
          fields.tooltipText[i18n.language] || fields.tooltipText[i18n.options.fallbackLng[0]]

        const buttonText =
          fields.buttonText[i18n.language] || fields.buttonText[i18n.options.fallbackLng[0]]

        // Renders a tooltip reference
        if (
          contentType &&
          contentType.sys.type === 'Link' &&
          contentType.sys.linkType === 'ContentType' &&
          contentType.sys.contentful_id === 'tooltip'
        ) {
          return (
            <Tooltip id={`tooltip-${contentType.contentful_id}`} tooltipText={tooltipText}>
              {buttonText}
            </Tooltip>
          )
        }

        return <div data-qa="rich-text-embedded-entry">{children}</div>
      },
      [BLOCKS.UL_LIST]: (node, children) => (
        <List className="rte-block" type={listType === 'checklist' ? 'checklist' : 'bullet'}>
          {children}
        </List>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <List className="rte-block" type="number">
          {children}
        </List>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => <List.Item>{children}</List.Item>,
      [BLOCKS.QUOTE]: (node, children) => <blockquote data-qa="blockquote">{children}</blockquote>,
      [BLOCKS.HR]: () => <hr data-qa="horizontal-rule" />,
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const [t, i18n] = useTranslation()
        const fields = node.data.target.fields || {}

        const file = fields.file[i18n.language] || fields.file[i18n.options.fallbackLng[0]]

        if (!file) {
          // eslint-disable-next-line no-console
          console.log('unprocessable node', node)
          return null
        }

        const fileUrl = file.url
        const readableFileName =
          node.content && node.content.length > 0 ? node.content[0].value : 'File'

        return (
          <Link
            to={`https:${fileUrl}`}
            light={theme === 'light'}
            alt={t(`RichText.downloadFileAriaLabel`, {
              defaultValue: '{{readableFileName}}',
              readableFileName,
            })}
            download
            contentType={file.contentType}
            target="_blank"
            bypassConfirmation
          >
            {children}
          </Link>
        )
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const [_t, i18n] = useTranslation()
        const { contentType } = node.data.target.sys
        const fields = node.data.target.fields || {}

        const referenceLabel =
          fields.referenceLabel[i18n.language] || fields.referenceLabel[i18n.options.fallbackLng[0]]

        // Renders a footnote reference
        if (
          contentType &&
          contentType.sys.type === 'Link' &&
          contentType.sys.linkType === 'ContentType' &&
          contentType.sys.contentful_id === 'footnote'
        ) {
          return (
            <Link
              to={`#fn-${node.data.target.sys.contentful_id}`}
              alt={referenceLabel}
              aria-describedby={`fn-${node.data.target.sys.contentful_id}`}
              light={theme === 'light'}
            >
              {children}
            </Link>
          )
        }

        return null
      },
      [INLINES.EMBEDDED_ENTRY]: node => {
        const [_t, i18n] = useTranslation()
        const { contentType } = node.data.target.sys
        const fields = node.data.target.fields || {}

        const tooltipText =
          fields.tooltipText[i18n.language] || fields.tooltipText[i18n.options.fallbackLng[0]]

        const buttonText =
          fields.buttonText[i18n.language] || fields.buttonText[i18n.options.fallbackLng[0]]

        // Renders a tooltip reference
        if (
          contentType &&
          contentType.sys.type === 'Link' &&
          contentType.sys.linkType === 'ContentType' &&
          contentType.sys.contentful_id === 'tooltip'
        ) {
          return (
            <Tooltip id={`tooltip-${contentType.contentful_id}`} tooltipText={tooltipText}>
              {buttonText}
            </Tooltip>
          )
        }

        return inline(INLINES.EMBEDDED_ENTRY, node)
      },
      [INLINES.HYPERLINK]: (node, children) => {
        const {
          data: { uri },
        } = node

        if (uri.includes('?id=modal-holiday-hours')) {
          return <ModalTableLink to={node.data.uri}>{children}</ModalTableLink>
        }

        return (
          <Link to={node.data.uri} light={theme === 'light'}>
            {children}
          </Link>
        )
      },
      ...overrides,
    },
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
      [MARKS.UNDERLINE]: text => <u>{text}</u>,
      [MARKS.CODE]: text => <code>{text}</code>,
    },
    renderText: text => {
      const textLines = text.split('\n')
      const textArray = compact(intersperse(textLines, <br aria-hidden="true" />))
      return textArray.length === 1 ? textArray[0] : textArray
    },
  }
}

const RichText = ({ listType, theme, children: richTextDocument, overrides }) => {
  const options = getOptions({ listType, theme, overrides })
  return documentToReactComponents(richTextDocument, options)
}

RichText.propTypes = {
  children: PropTypes.object,
  listType: PropTypes.oneOf(['default', 'checklist']),
  theme: PropTypes.oneOf(['default', 'light']),
  overrides: PropTypes.object,
}

export { RichText }
export default RichText
