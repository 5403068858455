// Lightweight detection list adapted from https://stackoverflow.com/questions/58905682/how-do-i-use-a-utlity-function-to-detect-os-and-browser-in-react

export function getOperatingSystem() {
  let operatingSystem = 'Not known'

  if (window.navigator.appVersion.indexOf('Win') !== -1) {
    operatingSystem = 'Windows'
  }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) {
    operatingSystem = 'Mac'
  }
  if (window.navigator.appVersion.indexOf('X11') !== -1) {
    operatingSystem = 'Unix'
  }
  if (window.navigator.appVersion.indexOf('Linux') !== -1) {
    operatingSystem = 'Linux'
  }

  return operatingSystem
}

export function getBrowser() {
  let currentBrowser = 'Not known'

  const ua = window.navigator.userAgent

  if (ua.indexOf('Chrome') !== -1) {
    currentBrowser = 'Chrome'
  } else if (ua.indexOf('Firefox') !== -1) {
    currentBrowser = 'Firefox'
  } else if (ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0) {
    currentBrowser = 'IE'
  } else if (ua.indexOf('Edge') !== -1) {
    currentBrowser = 'Edge'
  } else if (ua.indexOf('Safari') !== -1) {
    currentBrowser = 'Safari'
  } else if (ua.indexOf('Opera') !== -1) {
    currentBrowser = 'Opera'
  }

  return currentBrowser
}
