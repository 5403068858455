/* eslint-disable no-bitwise */
import { useMemo, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMegaphone } from '@fortawesome/pro-solid-svg-icons'
import { RichText } from '../../rich-text'
import { Container } from '../../grid'
import { Alert } from '../../alert'
import * as styles from './Banner.module.scss'

/* Simple hash function. */
function hash(s) {
  let a = 1
  let c = 0
  let h
  let o
  if (s) {
    a = 0
    for (h = s.length - 1; h >= 0; h -= 1) {
      o = s.charCodeAt(h)
      a = ((a << 6) & 268435455) + o + (o << 14)
      c = a & 266338304
      a = c !== 0 ? a ^ (c >> 21) : a
    }
  }
  return String(a)
}

const megaphone = <FontAwesomeIcon icon={faMegaphone} data-qa="banner-announcement-icon" />

const Banner = ({ alert, className }) => {
  const [, i18n] = useTranslation()
  const [show, setShow] = useState(false)
  const classes = classNames(styles.Banner, className)

  const { id } = alert.sys
  const title = alert.fields.title[i18n.language]
  const richBody = alert.fields.richBody[i18n.language]

  const STORAGE_KEY = useMemo(() => {
    return `banner-${hash(id + title + documentToPlainTextString(richBody))}`
  }, [alert])

  useEffect(() => {
    const dismissedBanner = window.localStorage.getItem(STORAGE_KEY)
    if (!dismissedBanner) {
      setShow(true)
    }
  }, [STORAGE_KEY])

  const handleDismiss = useCallback(() => {
    window.localStorage.setItem(STORAGE_KEY, Date.now())
    setShow(false)
  }, [STORAGE_KEY])

  if (!show) {
    return null
  }

  return (
    <div className={classes} data-qa="banner-container">
      <Container className={styles.Container}>
        <Alert icon={megaphone} title={title} onDismiss={handleDismiss}>
          <RichText
            theme="light"
            overrides={{
              paragraph: (_, richTextChildren) => richTextChildren,
            }}
          >
            {richBody}
          </RichText>
        </Alert>
      </Container>
    </div>
  )
}

Banner.propTypes = {
  alert: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export { Banner }
export default Banner
